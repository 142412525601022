<div class="d-flex justify-content-between bd-bottom p-3 bg-white">
  <h3 class="font-weight-bold mb-2 text-color-2" i18n>Pregled email poruka</h3>
  <div class="d-flex">
    <div>
      <!-- <span class="mr-3" i18n>Tražilica:</span> -->
    </div>
  </div>
</div>

<div class="p-3">
  <div class="container-fluid">
    <div class="row bg-color2 p-2 d-none d-lg-flex" *ngIf="emails?.length">
      <div class="col-2" i18n>Datum slanja</div>
      <div class="col-8" i18n>Naslov</div>
      <div class="col-2" i18n></div>
    </div>

    <ng-container *ngIf="emails?.length">
      <div class="row bg-white bd-bottom p-2" [class.mb-4]="last" *ngFor="let email of emails; let last = last;">
        <div class="col-3 mb-2 font-weight-bold d-lg-none" i18n>Datum slanja</div>
        <div class="col-9 mb-2 col-lg-2 mb-lg-0">{{ email.date | date: 'dd.MM.yyyy.' }}</div>

        <div class="col-3 mb-2 font-weight-bold d-lg-none" i18n>Naslov</div>
        <div class="col-9 mb-2 col-lg-8 mb-lg-0">{{ email.subject }}</div>

        <div class="col-3 mb-2 font-weight-bold d-lg-none" i18n></div>
        <div class="col-9 mb-2 col-lg-2 mb-lg-0"><button class="btn rounded-light btn-sm btn-outline-dark" (click)="readEmail(emailTemplateModal, email)" i18n>Pročitaj poruku</button></div>
      </div>
      <app-pager [pager]="pager"></app-pager>
    </ng-container>
  </div>
  <div *ngIf="!emails?.length && !loading" i18n>Nemaš poslanih email poruka</div>
  <app-loader *ngIf="loading"></app-loader>
</div>

<!-- Create zone modal -->
<ng-template #emailTemplateModal>
  <div class="modal-header">
    <h5 i18n>{{selectedItem.subject}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-email-details [email]="selectedItem"></app-email-details>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button class="btn btn-primary rounded-light" (click)="modalRef.hide()" i18n>Zatvori</button>
  </div>
</ng-template>
