import {FormGroup} from '@angular/forms';

/**
 * custom validator to check that two fields match
 * @param controlName string
 * @param matchingControlName string
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({mustMatch: true});
    } else {
      matchingControl.setErrors(null);
    }
  };
}

/**
 * Sets dependent control to required if first control has values
 * @param controlName string
 * @param dependentControlName string
 */
export function MutualDependence(controlName: string, dependentControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const dependentControl = formGroup.controls[dependentControlName];

    // Skip validation if main control is empty
    if (control.value === undefined || control.value === null || control.value === '') {
      // Get current errors excluding our 'dependent' error
      const currentErrors = dependentControl.errors || {};
      if (currentErrors['dependent']) {
        // Remove only our error and preserve others
        const { dependent, ...otherErrors } = currentErrors;
        dependentControl.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
      }
      return;
    }

    // Check if dependent control is empty
    if (dependentControl.value === undefined || dependentControl.value === null || dependentControl.value === '') {
      // Get current errors and add our error while preserving others
      const currentErrors = dependentControl.errors || {};
      dependentControl.setErrors({ ...currentErrors, dependent: true });
    } else {
      // Remove only our error if it exists, but preserve others
      const currentErrors = dependentControl.errors || {};
      if (currentErrors['dependent']) {
        const { dependent, ...otherErrors } = currentErrors;
        dependentControl.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
      }
    }
  };
}