import {Component, Inject, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {faEdit, faSpinner, faTimes, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import {CartOrderItem} from '../../cart-order-item';
import {Currency} from '../../../_models/currency';
import {CartService} from '../../cart.service';
import {OrderService} from '../../order.service';
import {ProductDomainItem} from '../../product-domain-item';
import {Product} from '../../../_models/product';
import {Promotion} from '../../promotion';
import {ProductService} from '../../../_services/product.service';
import {AlertService, AuthenticationService} from '../../../_services';
import {Client} from '../../../_models';
import {APP_CONFIG, AppConfig} from "../../../app-config.module";
import { PromotionService } from '../../promotion.service';
import { Contact } from 'src/app/profile/contacts';
import { take } from 'rxjs/operators';
import { DomainService } from 'src/app/domains/domain.service';
import { DomainDataComponent } from './domain-data';

@Component({
  selector: 'app-step-overview',
  templateUrl: './step-overview.component.html'
})
export class StepOverviewComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() product: string;
  @Input() redirectWithoutUser = false;
  @Input() filter: { gId: number };

  faSpinner = faSpinner;
  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faTimes = faTimes;
  faExclamationCircle = faExclamationCircle;

  user?: Client;
  orderForm: FormGroup;
  orderItems: CartOrderItem[];
  public domainContact?: Contact;
  public userContactValid = true;
  domainItems: ProductDomainItem[];
  public incompleteDomains: ProductDomainItem[] = [];
  cartCurrency: Currency;
  products: Product[] = [];
  promotion: Promotion;
  submitted = false;
  loading = {
    code: false
  };
  public displayHrk = false;
  public modalRef: BsModalRef;
  public isDomainConfigurationModalShown = false;

  constructor(
    private router: Router,
    private cartService: CartService,
    private orderService: OrderService,
    private promotionService: PromotionService,
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private alert: AlertService,
    private auth: AuthenticationService,
    private modalService: BsModalService,
    private domainService: DomainService,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {
    this.orderForm = this.formBuilder.group({
      currency: null,
      coupon: null,
      affId: null,
      total: null,
      totalDiscount: null,
      totalTax: 0.0,
      totalWithTax: 0.0,
      contactid: null,
      tos: [null, Validators.required]
    });
    this.subscription.add(this.cartService.getCartCurrency()
      .subscribe(cartCurrency => {
        this.cartCurrency = cartCurrency;
        this.orderForm.patchValue({currency: cartCurrency?.code});
      }));

    const domainContact = this.cartService.getDomainContact();
    this.domainContact = domainContact;
    this.orderForm.patchValue({
      affId: this.cartService.getAff(),
      contactid: domainContact?.contactid,
    });
  }

  get f() {
    return this.orderForm.controls;
  }

  ngOnInit() {
    this.displayHrk = this.config.displayHrk;

    this.subscription.add(this.auth.currentUser
      .subscribe(user => {
        if (!user && this.redirectWithoutUser) {
          this.router.navigate(['/cart']);
        } else if (user) {
          this.user = user;
        }
      }));

    this.subscription.add(
      this.cartService.orderItems$
        .subscribe((orderItems) => {
          if (this.filter !== undefined) {
            orderItems = (this.filter.gId !== undefined && orderItems !== null) ?
              orderItems.filter(x => x.gid === this.filter.gId) : orderItems;
          }
          this.orderItems = orderItems;
          this.updateCartPriceInForm();
        })
    );

    if (this.filter === undefined) {
      this.subscription.add(
        this.cartService.domainItems$
          .subscribe((domainItems) => {
            this.domainItems = domainItems;
            this.updateCartPriceInForm();
            this.checkDomainAdditionalFields();
            this.checkForContactAndUserValidity();
          })
      );
      if ((!this.orderItems || this.orderItems.length === 0) && (!this.domainItems || this.domainItems.length === 0)) {
        return this.router.navigate(['/cart']);
      }
    }

    this.subscription.add(
      this.cartService.getPromotion$()
        .subscribe(promotion => {
          this.promotion = promotion;
          this.orderForm.patchValue({
            coupon: promotion?.code || null,
            total: this.cartService.getTotalCart(),
            totalDiscount: this.cartService.getTotalCart(true),
            totalTax: this.cartService.getTotalCartTax(this.user),
            totalWithTax: Math.round(((this.cartService.getTotalCart() + this.cartService.getTotalCartTax(this.user)) + Number.EPSILON)*100)/100
          });
        })
    );

    this.subscription.add(
      this.productService.getProducts(null, null, null, this.cartCurrency?.code)
        .subscribe(prods => {
          this.products = prods;
          this.checkIfProductHasRequiredDomain();
        })
    );

    this.updateCartPrice();
  }

  updateCartPrice() {
    this.cartService.loadItems();
    this.updateCartPriceInForm();
  }

  private updateCartPriceInForm(): void {
    this.orderForm.patchValue({
      total: this.cartService.getTotalCart(),
      totalDiscount: this.cartService.getTotalCart(true),
      totalTax: this.cartService.getTotalCartTax(this.user),
      totalWithTax: Math.round(((this.cartService.getTotalCart() + this.cartService.getTotalCartTax(this.user)) + Number.EPSILON)*100)/100
    });
  }

  private checkForContactAndUserValidity(): void {
    // If there's a chosen domain contact, check if it's valid for Croatian domains
    // If the chosen contact is not valid for Croatian domains, clear it
    if (!this.checkIfContactIsValidForCarnetDomains(this.domainContact)) {
      this.clearDomainContact();
    }

    // If there's no contact chosen, check if the user is valid for Croatian domains
    if (
      !this.domainContact &&
      !this.checkIfContactIsValidForCarnetDomains(this.user)
    ) {
      this.userContactValid = false;
    } else {
      this.userContactValid = true;
    }
  }

  removeDomain(domain: ProductDomainItem) {
    this.cartService.removeDomain(domain);
    this.updateCartPrice();
  }

  checkCoupon() {
    this.loading.code = true;
    const promotionSub = this.promotionService.getPromotion(this.orderForm.value.coupon)
      .subscribe(promotion => {
        this.loading.code = false;
        this.promotion = promotion;
        if (promotion) {
          const promoStatus: {
            applicable: boolean,
            requiredProductIds: any[]
          } = this.cartService.applyPromotion(promotion);
          if (!promoStatus.applicable) {
            this.alert.error($localize`Promocijski kod nije primjenjiv na trenutne usluge`);
          }
          if (promoStatus.requiredProductIds.length > 0) {
            const invalidProductDomains = promoStatus.requiredProductIds.filter(x => isNaN(x));
            let missingProductNames: any[] = [];
            this.subscription.add(
              this.productService.getProducts(null, null, null, this.cartCurrency?.code)
                .subscribe(prods => {
                  const filteredProducts = (prods && prods.length > 0) ?
                    prods.filter(x =>  promoStatus.requiredProductIds.indexOf(x.pid) >= 0)
                    : [];
                  missingProductNames = [...filteredProducts, ...invalidProductDomains];
                  if (missingProductNames.length > 0) {
                    let message = 'Missing products:<br/>';
                    missingProductNames.forEach(x => {
                      message += (isNaN(x) && typeof x !== 'object') ? `Domena ${x}<br/>` : `${this.productService.getName(x)}<br/>`;
                    });
                    this.alert.info(message, true, null, {
                      enableHtml: true
                    });
                  }
                })
            );
          }
          this.orderForm.patchValue({coupon: promotion.code});
          this.updateCartPrice();
        }
      });
    this.subscription.add(promotionSub);
  }

  removeOrderItem(orderItem: CartOrderItem) {
    this.cartService.removeOrderItem(orderItem);
    this.updateCartPrice();
  }

  addQuantity(orderItem) {
    this.setQuantity(orderItem, ++orderItem.quantity);
  }

  removeQuantity(orderItem) {
    this.setQuantity(orderItem, --orderItem.quantity);
  }

  setQuantity(orderItem: CartOrderItem, quantity: number) {
    quantity = (quantity < 1) ? 1 : parseInt(String(quantity), 10);
    orderItem.quantity = quantity;
    this.cartService.addOrderItem(orderItem);
    this.updateCartPrice();
  }

  createOrder() {
    this.submitted = true;
    if (this.orderForm.invalid || !this.checkIfProductHasRequiredDomain()) {
      return false;
    }
    if (!this.user || this.user === undefined) {
      return this.router.navigate([`/cart/user`], {
        queryParams: {
          returnUrl: "/cart/overview",
        },
      });
    }

    if (!this.promotion) {
      this.orderService.saveOrder(this.orderForm.value);
      this.router.navigate([`/cart/payment`]);
    }

    // If there's a promotion, check if it's still valid
    if (this.promotion) {
      this.cartService.loadItems(); // Used to check if promotion is still valid
      const isPromotionValid = this.cartService.isPromotionValid();

      // If there's no promotion anymore, it means it's expired
      // Don't allow the user to proceed
      if (!isPromotionValid) {
        return false;
      }

      // If the promotion in the local storage is still valid,
      // check API to see if it's the same
      this.promotionService
        .getPromotion(this.promotion.code)
        .subscribe((promotion) => {
          if (!promotion || new Date(promotion.expirationDate) < new Date()) {
            this.alert.info($localize`Promo kod je istekao`);
            this.auth.removeStorage(CartService.promotionKey);
            this.cartService.loadItems();
            return false;
          }

          this.orderService.saveOrder(this.orderForm.value);
          this.router.navigate([`/cart/payment`]);
        });
    }
  }

  getTosLink() {
    return `${this.config.tos}`;
  }

  getSupportLink() {
    return `${this.config.appRoot}/support/ticket/new`;
  }
  
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  openModalForEditingDomainItems() {
    if (this.isDomainConfigurationModalShown) {
      return;
    }

    this.modalRef = this.modalService.show(DomainDataComponent, {
      class: "modal-lg",
      initialState: {
        domains: JSON.parse(JSON.stringify(this.domainItems)), // Clone the array to prevent changes in the modal from affecting the original array
      },
    });

    this.isDomainConfigurationModalShown = true;

    this.modalRef.onHide.subscribe(() => {
      this.isDomainConfigurationModalShown = false;
    });

    this.modalRef.content.changed.pipe(take(1)).subscribe(() => {
      this.setDomainOwner();
      this.checkDomainAdditionalFields();
      this.checkForContactAndUserValidity();
    });
  }

  public setDomainOwner(): void {
    // Do not update anything if the user is the owner of the domain
    const domainContact = this.cartService.getDomainContact();
    if (domainContact?.contactid === this.user.id) {
      this.domainContact = null;
      return;
    }

    this.orderForm.patchValue({
      contactid: domainContact?.contactid,
    });
    this.domainContact = domainContact;
    this.modalRef.hide();
  }

  public clearDomainContact(): void {
    this.cartService.setDomainContact(null);
    this.domainContact = null;
    this.orderForm.patchValue({
      contactid: null,
    });
  }

  private checkIfContactIsValidForCarnetDomains(userData?: Contact | Client): boolean {
    let contactValid = true;

    if (!userData) {
      return false;
    }

    const cartContainsCroatianDomain = this.domainItems?.some((domain) => {
      return domain.extension === "hr" || domain.extension === "com.hr";
    });

    // For Croatian domains, the contact must have a valid tax number
    if (cartContainsCroatianDomain && !userData.tax_id) {
      contactValid = false;
    }

    return contactValid;
  }

  private checkDomainAdditionalFields(): void {
    // All domains within groups must have all required fields filled and all additional fields the same
    const domainsGroupedByExtension: Record<string, ProductDomainItem[]> = {};
    this.incompleteDomains = [];

    this.domainItems?.forEach((domain) => {
      let mappedExtension = domain.extension;
      if (mappedExtension === "hr" || mappedExtension === "com.hr") {
        mappedExtension = "hr / com.hr";
      }

      if (!domainsGroupedByExtension[mappedExtension]) {
        domainsGroupedByExtension[mappedExtension] = [];
      }

      domainsGroupedByExtension[mappedExtension].push(domain);
    });

    Object.keys(domainsGroupedByExtension).forEach((extension) => {
      const domains = domainsGroupedByExtension[extension];
      domains.forEach((domain) => {
        const doAllAdditionalFieldsMatch = domains.every((d) => {
          return (
            d.additionalFields.length === domain.additionalFields.length &&
            d.additionalFields.every((field, index) => {
              return (
                field.name === domain.additionalFields[index].name &&
                field.value === domain.additionalFields[index].value
              );
            })
          );
        });

        if (!doAllAdditionalFieldsMatch) {
          this.incompleteDomains = this.incompleteDomains.concat(domains);
        }
      });

      const incompleteDomains = domains.filter((domain) => {
        return domain.additionalFields.some(
          (field) => field.required && !field.value
        );
      });

      this.incompleteDomains = this.incompleteDomains.concat(incompleteDomains);
    });

    if (this.incompleteDomains.length > 0) {
      this.openModalForEditingDomainItems();
    }
  }

  private checkIfProductHasRequiredDomain(): boolean {
    let isValid = true;

    this.orderItems?.forEach((orderItem) => {
      const product = this.products.find((x) => x.pid === orderItem.pid);

      // If showdomainoptions is 1, then the domain is required
      if (!product || (product.showdomainoptions === 1 && !orderItem.domain)) {
        isValid = false;
        orderItem.errors = ["noDomainChosen"];
      } else {
        orderItem.errors = [];
      }
    });

    return isValid;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
