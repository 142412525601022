import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {NgSelectModule} from '@ng-select/ng-select';
import {FileUploadModule} from 'ng2-file-upload';

import {SupportRoutingModule} from './support-routing.module';
import {SupportComponent} from './support.component';
import {LoaderModule} from '../loader/loader.module';
import {TicketNewComponent} from './tickets/new';
import {TicketListComponent} from './tickets/list';
import {TicketDetailsComponent} from './tickets/details';
import {TicketViewComponent} from './tickets/view';
import {AnnouncementListComponent} from './announcements/list';
import {AnnouncementViewComponent} from './announcements/view';
import {ServerStatusComponent} from './server-status';
import {PagerComponent} from './pager';
import {NonRoutePagerComponent} from './non-route-pager';
import {TicketReplyComponent} from './tickets/reply/ticket-reply.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {EmailListComponent} from './emails/list';
import {EmailDetailsComponent} from './emails/details';
import {ExternalUrlModule} from '../shared/external-url';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    SupportRoutingModule,
    ReactiveFormsModule,
    LoaderModule,
    AngularSvgIconModule,
    NgSelectModule,
    FileUploadModule,
    ExternalUrlModule,
    TooltipModule
  ],
  exports: [
    PagerComponent,
    NonRoutePagerComponent,
    TicketListComponent,
    TicketNewComponent
  ],
  declarations: [
    TicketReplyComponent,
    TicketNewComponent,
    TicketListComponent,
    SupportComponent,
    EmailDetailsComponent,
    EmailListComponent,
    TicketDetailsComponent,
    TicketViewComponent,
    AnnouncementListComponent,
    AnnouncementViewComponent,
    ServerStatusComponent,
    PagerComponent,
    NonRoutePagerComponent
  ]
})
export class SupportModule {}
