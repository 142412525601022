<div class="d-flex justify-content-between bd-bottom p-3 bg-white flex-wrap align-items-center">
  <div class="d-flex mb-3 mb-sm-0">
    <a (click)="goBackToPrevPage()" tooltip="Vrati se na listu računa" i18n-tooltip="Vrati se na listu računa" class="link-black mr-3">&#8592;</a>
    <h3 class="color-dark-blue">
      <span class="mr-2" *ngIf="invoice?.status.toLowerCase() === 'paid'" i18n>Račun broj:</span>
      <span class="mr-2" *ngIf="['unpaid', 'cancelled'].indexOf(invoice?.status.toLowerCase()) !== -1" i18n>Ponuda broj:</span>
      <span *ngIf="invoice?.invoicenum !== ''; else invoiceOffer">{{invoice?.invoicenum}}</span>
      <ng-template #invoiceOffer>
        <span>{{invoice?.invoiceid}}</span>
      </ng-template>
    </h3>
  </div>
  <button class="btn btn-sm btn-outline-info" tooltip="Preuzmi račun" i18n-tooltip="Preuzmi račun" (click)="getInvoiceFile()"
          *ngIf="!loading.invoice && invoice && !loading.file">
    <svg-icon src="assets/svg/FAQ-down-blue.svg" class="icon icon-15 mr-2"></svg-icon>
    <span *ngIf="invoice?.status.toLowerCase() === 'paid'" i18n>Preuzmi račun</span>
    <span *ngIf="['unpaid', 'cancelled'].indexOf(invoice?.status.toLowerCase()) !== -1" i18n>Preuzmi ponudu</span>
  </button>
  <app-loader *ngIf="loading.invoice || loading.file"></app-loader>
</div>

<div class="p-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2 shadow p-3 bg-white">
        <app-loader *ngIf="loading.invoice"></app-loader>
        <ng-container *ngIf="invoice?.id">
          <div class="d-flex flex-wrap justify-content-between">
            <h3 class="mb-3 mb-sm-0 mr-3 mr-sm-0">
              <span class="mr-2" *ngIf="invoice?.status.toLowerCase() === 'paid'" i18n>Račun broj:</span>
              <span class="mr-2" *ngIf="['unpaid', 'cancelled'].indexOf(invoice?.status.toLowerCase()) !== -1" i18n>Ponuda broj:</span>
              {{invoice?.status === 'Paid' && invoice?.invoicenum !== '' ? invoice?.invoicenum : invoice?.invoiceid}}</h3>

            <div class="d-flex flex-column justify-content-center">
              <h3 class="font-weight-bold mb-2" [ngClass]="{
                'text-danger': invoice.status.toLowerCase() === 'unpaid',
                'text-success': invoice.status.toLowerCase() === 'paid',
                'text-warning': invoice.status.toLowerCase() === 'cancelled'}">
                <span *ngIf="invoice.status === 'Paid'" i18n>PLAĆENO</span>
                <span *ngIf="invoice.status === 'Unpaid'" i18n>NEPLAĆENO</span>
                <span *ngIf="invoice.status === 'Cancelled'" i18n>OTKAZANO</span>
              </h3>

              <ng-container *ngIf="invoice.status.toLowerCase() === 'unpaid'">
                <p class="mb-2"><span class="mr-2" i18n>Rok za plaćanje</span>{{invoice.duedate | date: 'dd.MM.yyyy.'}}</p>
                <p *ngIf="invoice.paymentmethod === 'monri'">
                  <button class="btn btn-sm btn-green rounded-light w-100 d-block" (click)="openModal(paymentCardsModal)" *ngIf="!loading.addingTransaction && !loading.form" i18n>Plati odmah</button>
                  <app-loader *ngIf="loading.addingTransaction"></app-loader>
                </p>
                <p *ngIf="invoice.paymentmethod === 'paypal'">
                  <app-loader *ngIf="loading.currency || loading.addingTransaction"></app-loader>
                  <ngx-paypal *ngIf="!loading.addingTransaction && !loading.form" [config]="payPalConfig"></ngx-paypal>
                </p>
              </ng-container>
            </div>
          </div>

          <hr>

          <div class="row mb-4" *ngIf="invoice?.cached_data.invoiceowner">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <div class="row font-weight-bold mb-2">
                <div class="col-12">
                  <span class="btn-link small" (click)="openModal(changeOwnerDataModal)" *ngIf="invoice.status.toLowerCase() === 'unpaid'"><fa-icon tooltip="Izmijeni podatke za račun" i18n-tooltip="Izmijeni podatke za račun" [icon]="faEdit"></fa-icon><span i18n>izmijeni</span></span>
                </div>
              </div>
              <div class="row fsmaller" *ngIf="!invoice?.cached_data.invoiceowner.companyname">
                <span class="col-lg-3 col-md-6 font-weight-bold" i18n>Ime i prezime: </span>
                <span class="col-lg-9 col-md-6 text-lg-left">{{invoice.cached_data.invoiceowner.firstname}} {{invoice.cached_data.invoiceowner.lastname}}</span>
              </div>
              <div class="row fsmaller" *ngIf="invoice?.cached_data.invoiceowner.companyname">
                <span class="col-lg-3 col-md-6 font-weight-bold" i18n>Naziv tvrtke: </span>
                <span class="col-lg-9 col-md-6 text-lg-left">{{invoice.cached_data.invoiceowner.companyname}}</span>
              </div>
              <div class="row fsmaller">
                <span class="col-lg-3 col-md-6 font-weight-bold" i18n>Adresa: </span>
                <span class="col-lg-9 col-md-6 text-lg-left">{{invoice.cached_data.invoiceowner.address1}}, {{invoice.cached_data.invoiceowner.city}}
                  , {{invoice.cached_data.invoiceowner.state}}, {{invoice.cached_data.invoiceowner.postcode}}
                  , {{invoice.cached_data.invoiceowner.countryname}}</span>
              </div>
              <div class="row fsmaller">
                <span class="col-lg-3 col-md-6 font-weight-bold" i18n>E-mail: </span>
                <span class="col-lg-9 col-md-6 text-lg-left">{{invoice.cached_data.invoiceowner.email}}</span>
              </div>
              <div class="row fsmaller">
                <span class="col-lg-3 col-md-6 font-weight-bold" i18n>OIB: </span>
                <span class="col-lg-9 col-md-6 text-lg-left">{{invoice.cached_data.invoiceowner.tax_id}}</span>
              </div>
            </div>

            <div class="col-12 col-lg-6 mb-3 mb-lg-0" *ngIf="invoice.status.toLowerCase() === 'paid'">
              <div class="row fsmaller text-lg-right">
                <span class="col-lg-7 col-md-6 text-lg-right font-weight-bold" i18n>Datum isporuke: </span>
                <span class="col-lg-5 col-md-6 text-lg-left">{{invoice.deliverydate | date: 'dd.MM.yyyy.'}}</span>
              </div>
              <div class="row fsmaller text-lg-right">
                <span class="col-lg-7 col-md-6 text-lg-right font-weight-bold" i18n>Mjesto izdavanja: </span>
                <span class="col-lg-5 col-md-6 text-lg-left">Zagreb</span>
              </div>
              <div class="row fsmaller text-lg-right">
                <span class="col-lg-7 col-md-6 text-lg-right font-weight-bold" i18n>Oznaka operatera: </span>
                <span class="col-lg-5 col-md-6 text-lg-left">{{invoice.fiscal_data.operatortag}}</span>
              </div>
              <div class="row fsmaller text-lg-right">
                <span class="col-lg-7 col-md-6 text-lg-right font-weight-bold" i18n>Odgovorna osoba: </span>
                <span class="col-lg-5 col-md-6 text-lg-left">IVONA MARIČIĆ</span>
              </div>
              <div class="row fsmaller text-lg-right">
                <span class="col-lg-7 col-md-6 text-lg-right font-weight-bold" i18n>Valuta: </span>
                <span class="col-lg-5 col-md-6 text-lg-left">{{invoice.cached_data.currencytag}}</span>
              </div>
            </div>

            <div *ngIf="invoice.paymentmethod === 'banktransfer'" class="col-12 col-lg-6 text-lg-right">
              <p class="font-weight-bold" i18n>Plati na</p>
              <div class="fsmaller" [innerHTML]="companyData" *ngIf="companyData"></div>
              <div class="fsmaller" *ngIf="refNumber"><span class="d-block" i18n>Poziv na broj:</span>{{refNumber}}</div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <p class="mb-1 font-weight-bold" i18n>Vrijeme izdavanja</p>
              <p>{{invoice.date | date: 'dd.MM.yyyy.'}}</p>
            </div>
            <div class="col-12 col-lg-6 text-lg-right">
              <p class="font-weight-bold mb-1" i18n>Način plaćanja</p>
              <select id="select-payment-method" class="rounded-light"
                      (change)="updateInvoicePaymentMethod($event.target.value)"
                      *ngIf="paymentMethods?.length && invoice?.status !== 'Paid'">
                <option *ngFor="let payMethItem of paymentMethods" value="{{payMethItem?.module}}"
                        [selected]="invoice.paymentmethod === payMethItem?.module">{{payMethItem?.displayname}}</option>
              </select>
              <p *ngIf="invoice.status.toLowerCase() !== 'unpaid'">{{invoice.paymentmethodname}}</p>
              <app-loader *ngIf="loading.paymentMethods"></app-loader>
            </div>
          </div>

          <div class="border mb-3" *ngIf="invoice?.status.toLowerCase() === 'unpaid' && client.credit > 0">
            <h5 class="bg-color4 p-lr-m p-tb-sm" i18n>Primijeni kredit</h5>
            <div class="p-lr-m p-tb-sm">
              <p *ngIf="currentCurrency?.code">
            <span
              i18n>Stanje tvog kredita je </span><span><b>{{client.credit | currency : currentCurrency?.code : 'symbol-narrow'}}</b>.</span>&nbsp;
                <span i18n>Korištenjem forme ispod i dostupnim kreditom možeš podmiriti iznos ponude.</span>
              </p>
              <p i18n>Unesi iznos koji želiš uplatiti:</p>
              <form *ngIf="client.credit > 0" [formGroup]="creditForm">
                <div class="form-group">
                  <div class="flex flex-between width-100 tac">
                    <input id="amount" formControlName="amount" type="text" class="form-control tac width-100"
                           [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"/>
                  </div>
                  <div *ngIf="submitted && f.amount.errors" class="invalid-feedback flex flex-between width-13 tar">
                    <div *ngIf="f.amount.errors.required" i18n>Iznos je obavezno polje</div>
                    <div *ngIf="f.amount.errors.max" i18n>Iznos za uplatu ne smije prelaziti iznos stanja tvog kredita
                    </div>
                  </div>
                </div>
                <button class="btn rounded-light btn-green btn-sm" (click)="applyCredit()" i18n>Primijeni kredit</button>
              </form>
            </div>
          </div>

          <div class="border mb-3">
            <h5 class="bg-grey p-2" i18n>Artikli na ponudi</h5>
            <div class="container-fluid">
              <div class="row p-2 d-none d-lg-flex bd-bottom">
                <div class="col-9 font-weight-bold" i18n>Opis zakupljene usluge</div>
                <div class="col-3 font-weight-bold text-right" i18n>Iznos</div>
              </div>

              <ng-container *ngIf="invoice.cached_data.currencytag && invoice?.items.length">
                <div class="row bd-bottom p-2" *ngFor="let item of invoice.items">
                  <div class="col-12 col-sm-4 mb-2 font-weight-bold d-lg-none">Opis zakupljene usluge</div>
                  <div class="col-12 col-sm-8 mb-2 col-lg-9 mb-lg-0">{{item.description}}<span *ngIf="item.taxed === 1">*</span></div>

                  <div class="col-12 col-sm-4 mb-2 font-weight-bold d-lg-none" i18n>Iznos</div>
                  <div class="col-12 col-sm-8 mb-2 col-lg-3 mb-lg-0 text-lg-right">{{item.amount | currency : invoice.cached_data.currencytag : 'symbol-narrow'}}</div>
                </div>
              </ng-container>
              <ng-container *ngIf="invoice.credit > 0">
                <div class="row bd-bottom p-2">
                  <div class="col-12 col-sm-4 mb-2 font-weight-bold d-lg-none" i18n>Opis zakupljene usluge</div>
                  <div class="col-12 col-sm-8 mb-2 col-lg-9 mb-lg-0" i18n>Odobreni popust ili preplata</div>

                  <div class="col-12 col-sm-4 mb-2 font-weight-bold d-lg-none" i18n>Iznos</div>
                  <div class="col-12 col-sm-8 mb-2 col-lg-3 mb-lg-0 text-lg-right">-{{invoice?.credit | currency : invoice.cached_data.currencytag : 'symbol-narrow'}}</div>
                </div>
              </ng-container>

               <ng-container *ngIf="invoice.cached_data.currencytag && invoice?.subtotal !== undefined">
               <!-- <div class="row bd-bottom p-2">
                  <div class="col-9 font-italic"><span i18n>Stanje kredita na tvom računu</span>*</div>
                  <div class="col-3 text-right">{{invoice?.cached_data.invoiceowner.credit | currency : currentCurrency.code : 'symbol-narrow'}}</div>
                </div> -->

                <div class="row bd-bottom p-2 bg-grey">
                  <div class="col-9 text-right"><strong i18n>Osnovica</strong></div>
                  <div class="col-3 text-right">{{invoice?.subtotal - invoice?.credit | currency : invoice.cached_data.currencytag : 'symbol-narrow'}}</div>
                </div>

                <div class="row bd-bottom p-2 bg-grey">
                  <div class="col-9 text-right"><strong i18n>{{invoice?.taxrate}}% PDV</strong></div>
                  <div class="col-3 text-right">{{invoice?.tax | currency : invoice.cached_data.currencytag : 'symbol-narrow'}}</div>
                </div>
                <div class="row bd-bottom p-2 bg-grey" *ngIf="invoice.tax2 > 0">
                  <div class="col-9 text-right"><strong i18n>{{invoice.taxrate2}}% Dodatni porez</strong></div>
                  <div class="col-3 text-right">{{invoice?.tax2 | currency : invoice.cached_data.currencytag : 'symbol-narrow'}}</div>
                </div>
                <div class="row p-2 bg-grey">
                  <div class="col-9 text-right"><strong i18n>Za platiti</strong></div>
                  <div class="col-3 text-right">{{invoice?.total | currency :invoice.cached_data.currencytag : 'symbol-narrow'}}
                    <br><span *ngIf="invoice.cached_data.currencytag === 'EUR' && displayHrk">({{invoice?.total*7.53450 | number: '1.2-2'}} HRK)</span>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
          <p class="mb-4" i18n>*Oporeziva stavka</p>

          <ng-container *ngIf="invoice?.notes.length">
            <h5 i18n>Napomena:</h5>
            <div class="mb-4" [innerHTML]="invoice.notes"></div>
          </ng-container>

          <div class="row p-2 p-2 d-none d-lg-flex border-bottom">
            <div class="font-weight-bold col-5" i18n>ID transakcije</div>
            <div class="font-weight-bold col-2" i18n>Datum transakcije</div>
            <div class="font-weight-bold col-3" i18n>Sučelje</div>
            <div class="font-weight-bold col-2 text-right" i18n>Iznos</div>
          </div>

          <ng-container *ngIf="invoice.transactions?.length">
            <div class="row bd-bottom p-2" *ngFor="let transaction of invoice.transactions">
              <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>ID transakcije</div>
              <div class="col-8 mb-2 col-lg-5 mb-lg-0">{{transaction.transid}}</div>

              <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Datum transakcije</div>
              <div class="col-8 mb-2 col-lg-2 mb-lg-0">{{transaction.date | date: 'dd.MM.yyyy.'}}</div>

              <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Sučelje</div>
              <div class="col-8 mb-2 col-lg-3 mb-lg-0 text-break">{{transaction.gatewayname}}</div>

              <div class="col-4 mb-2 font-weight-bold d-lg-none text-lg-right" i18n>Iznos</div>
              <div class="col-8 mb-2 col-lg-2 mb-lg-0 text-lg-right">{{transaction.amountin | currency : invoice.cached_data.currencytag : 'symbol-narrow'}}</div>
            </div>
          </ng-container>

          <div class="border-bottom p-2 text-center" *ngIf="!invoice.transactions?.length" i18n>Nije pronađena pripadajuća transakcija</div>

          <div class="row p-2 mb-2">
            <div class="col-4 col-lg-9 text-lg-right font-weight-bold" i18n>Otvoreni iznos</div>
            <div class="col-8 col-lg-3 text-lg-right">{{invoice?.balance | currency : invoice.cached_data.currencytag : 'symbol-narrow'}}
              <br><span *ngIf="invoice.cached_data.currencytag === 'EUR' && displayHrk">({{invoice?.balance*7.53450 | number: '1.2-2'}} HRK)</span>
            </div>
          </div>


          <ng-container *ngIf="invoice?.status === 'Paid'">
            <div class="row p-1 pl-2 pr-2">
              <div class="col-4 col-lg-1 font-weight-bold" i18n>ZKI:</div>
              <div class="col-8 col-lg-11">{{invoice.fiscal_data.zki}}</div>
            </div>

            <div class="row p-1 pl-2 pr-2 mb-2">
              <div class="col-4 col-lg-1 font-weight-bold" i18n>JIR:</div>
              <div class="col-8 col-lg-11">{{invoice.fiscal_data.jir}}</div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<ng-template #changeOwnerDataModal>
  <app-invoice-owner-data [modalRef]="modalRef" [invoice]="invoice"
                          (changed)="loadInvoice()"></app-invoice-owner-data>
</ng-template>
<div class="euro-banner p-2 text-center" *ngIf="currentCurrency.code === 'EUR' && displayHrk">Prema čl. 42 Zakona o uvođenju eura kao službene valute u Republici Hrvatskoj, dvojno iskazivanja cijena prema potrošačima u RH prikazuje se po fiksnom tečaju 1€ = 7,53450kn</div>
<ng-template #paymentCardsModal>
<div [ngClass]="{'hidden': hideCardlist}">
  <div class="modal-header">
    <div class="d-flex align-items-center">
      <!--      <svg-icon src="assets/svg/VPS-configuration.svg" class="icon icon-50 mr-2"></svg-icon>-->
      <h3 i18n>Odaberi karticu za plaćanje ili unesi novu</h3>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-card-list [parentModalRef]="modalRef"
                 [isModal]="true"
                 [invoice]="invoice"
                 (newCardPaid)="invoicePaid()"
                 (toggleModal)="hideCardModal($event)"
                 (selectedCard)="selectCard($event)"
                 *ngIf="invoice"></app-card-list>
</div>
</ng-template>

<ng-template #paymentInProgressModal>
  <div class="modal-header">
    <div class="d-flex align-items-center">
      <h2 i18n>Tvoja transakcija se obrađuje. Molimo te da ne napuštaš ovaj prozor dok proces plaćanja traje!</h2>
      <app-loader *ngIf="loading.addingTransaction"></app-loader>
    </div>
  </div>
</ng-template>
