<div class="d-flex align-items-center p-3 p-tb-sm bg-color1 mb-0">
  <a routerLink="{{returnToOptionCid === null ? '/cart' : '../options/' + returnToOptionCid}}" tooltip="Natrag na odabir proizvoda" i18n-tooltip="Natrag na odabir proizvoda"
     class="color-white btn btn-outline-light rounded-light mr-3" *ngIf="!productId"><</a>
  <a routerLink="./" [queryParams]="{gid: product.gid}" tooltip="Na listu backup proizvoda" i18n-tooltip="Na listu backup proizvoda"
     class="color-white btn btn-outline-light rounded-light mr-3" *ngIf="productId && product"><</a>
  <h3 class="color-white">Cloud backup</h3>
</div>

<div *ngIf="!productId; else editProduct">
  <div class="container-fluid bg-white" *ngIf="backupProducts?.length > 0">
    <div class="row d-flex justify-content-between align-items-center bd-bottom pt-2 pb-2" *ngFor="let backupProduct of backupProducts"
         [ngClass]="backupProduct.added ? 'bg-color8' : ''">
      <div class="col-md-6 mb-3 mb-md-0">
        <p class="font-weight-bold">{{getProductTranslatedName(backupProduct)}}</p>
        <p class="fsmall">{{getDescription(backupProduct)}}</p>
      </div>

      <div class="col-sm-6 col-md-3 mb-3 mb-sm-0">
        <!-- If there's only one type of prices, show a simple pricing -->
        <p *ngIf="backupProduct.prices.length === 1; else dropdownPrices">
          <ng-container *ngIf="backupProduct.prices.length === 1">
            <!-- Check if there are discounts available -->
            <p *ngIf="backupProduct.discountprices?.length === 1">
              <span class="font-weight-bold">
                {{backupProduct.discountprices?.[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
              </span>
              <span class="text-danger line-through font-italic">
                {{backupProduct.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
              </span>
              <span>&nbsp;/&nbsp;{{backupProduct.discountprices?.[0].nameTranslated}}</span>
            </p>

            <!-- If there are no discounts available, show regular price -->
            <p *ngIf="backupProduct.discountprices?.length === 0">
              <span class="font-weight-bold">{{backupProduct.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
              <span>&nbsp;/&nbsp;{{backupProduct.prices[0].nameTranslated}}</span>
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(backupProduct.prices[0].value*7.53450) | number: '1.2-2'}} HRK)</span>
            </p>
          </ng-container>
        </p>

        <!-- If there are multiple prices available, show select component -->
        <ng-template #dropdownPrices>
          <ng-select [items]="backupProduct.prices"
                     [searchable]="false"
                     [clearable]="false"
                     [(ngModel)]="backupProduct.pricingPeriodId"
                     bindValue="id"
                     bindLabel="name"
                     i18n-placeholder="Odaberi period"
                     placeholder="Odaberi period">
            <ng-template ng-label-tmp let-item="item">
              <!-- If there's a discount available for the item, show it -->
              <ng-container *ngIf="backupProduct.discountprices && getDiscountPricing(backupProduct.discountprices, backupProduct.pricingPeriodId, 'value') as discountedPrice; else regularMultiplePrice">
                <span>
                  {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                </span>
                <span class="text-danger line-through font-italic">{{getPricing(backupProduct.prices, backupProduct.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{getPricing(backupProduct.prices, backupProduct.pricingPeriodId, 'nameTranslated')}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(backupProduct.prices, backupProduct.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
              </ng-container>

              <!-- If there's no discount, show regular price -->
              <ng-template #regularMultiplePrice >
                <span>{{getPricing(backupProduct.prices, backupProduct.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{getPricing(backupProduct.prices, backupProduct.pricingPeriodId, 'nameTranslated')}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(backupProduct.prices, backupProduct.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
              </ng-template>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <!-- If there's a discount available for the item, show it -->
              <ng-container *ngIf="backupProduct.discountprices && getDiscountPricing(backupProduct.discountprices, item.id, 'value') as discountedPrice; else regularMultiplePrice">
                <span class="font-weight-bold">
                  {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                </span>
                <span class="text-danger line-through font-italic">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
              </ng-container>

              <!-- If there's no discount, show regular price -->
              <ng-template #regularMultiplePrice >
                <span class="font-weight-bold">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
              </ng-template>
            </ng-template>
          </ng-select>
        </ng-template>
      </div>

      <div class="col-sm-6 col-md-3">
        <button *ngIf="!backupProduct.stockcontrol || (backupProduct.stockcontrol && backupProduct.stocklevel > 0)"
                class="btn btn-bordered rounded-light btn-green btn-sm w-100 h-select"
                [ngClass]="backupProduct.added ? 'btn-active' : ''" (click)="setProduct(backupProduct)">
          <svg-icon src="assets/svg/shop-white.svg" class="icon icon-15 icon-shop mr-2"></svg-icon>
          <span *ngIf="!backupProduct.added; else removeCartItem;" i18n>Dodaj u košaricu</span>
          <ng-template #removeCartItem>
            <span i18n>Ukloni iz košarice</span>
          </ng-template>
        </button>

        <button *ngIf="backupProduct.stockcontrol && backupProduct.stocklevel <= 0"
                [disabled]="true"
                class="btn btn-bordered rounded-light btn-sm w-100 h-select">
          <span i18n>Trenutno nedostupno</span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #editProduct>
  <ng-container *ngIf="product?.pid && backupForm">
    <form [formGroup]="backupForm">
      <div class="container-fluid">
<!--        <div class="row bg-grey pt-2 align-items-center d-none d-lg-flex">-->
<!--          <div class="col col-lg-3 offset-lg-5 mb-3 mb-lg-0 p-2 font-weight-bold" *ngIf="product.prices && f.pricingPeriod.value" i18n>Količina:</div>-->
<!--          <div class="col col-lg-3 mb-3 mb-lg-0 p-2 font-weight-bold" *ngIf="product.prices && f.pricingPeriod.value" i18n>Cijena:</div>-->
<!--        </div>-->

        <div class="row bg-grey pb-2 pt-2 align-items-center justify-content-end d-lg-flex">
          <div class="col-lg-4 mb-3 mb-lg-0"><h5 class="font-weight-bold">{{getName(product)}}</h5></div>
          <div class="col-lg-2 mb-3 mb-lg-0">
            <div class="row font-weight-bold align-items-center">
              <label class="col-12 w-100" *ngIf="product.prices && f.pricingPeriod.value" i18n>Količina:</label>
              <div class="col-12 input-group">
                <div class="input-group-prepend down" (click)="removeQuantity()">
                  <div class="input-group-text">-</div>
                </div>
                <input type="text" formControlName="quantity" min="1" class="form-control text-center font-weight-bold" [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }" />
                <div class="input-group-append up" (click)="addQuantity()">
                  <div class="input-group-text">+</div>
                </div>
                <div *ngIf="submitted && f.quantity.errors" class="invalid-feedback w-100">
                  <div *ngIf="f.quantity.errors.required" i18n>Količina obavezna</div>
                  <div *ngIf="f.quantity.errors.min" i18n>Minimalno 1</div>
                  <div *ngIf="f.quantity.errors.numeric" i18n>Minimalno 1</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 mb-3 mb-lg-0"></div>
          <div class="col-lg-3 mb-3 mb-lg-0" *ngIf="product.prices && f.pricingPeriod.value">
            <label for="pricing{{product.id}}" class="col-12 w-100 pl-0" i18n>Osnovna cijena:</label>
            <ng-select [items]="product.prices"
                       (change)="setPricingPeriod($event)"
                       [searchable]="false"
                       [clearable]="false"
                       formControlName="pricingPeriod"
                       id="pricing{{product.id}}"
                       class="w-auto"
                       bindValue="id"
                       bindLabel="nameTranslated"
                       i18n-placeholder="Odaberi period"
                       placeholder="Odaberi period">
              <ng-template ng-label-tmp let-item="item">
                <!-- If there's a discount available for the item, show it -->
                <ng-container *ngIf="product.discountprices && getDiscountPricing(product.discountprices, product.pricingPeriodId, 'value') as discountedPrice; else regularMultiplePrice">
                  <span>
                    {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                  </span>
                  <span class="text-danger line-through font-italic">{{getPricing(product.prices, product.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{getPricing(product.prices, product.pricingPeriodId, 'nameTranslated')}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(product.prices, product.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
                </ng-container>
  
                <!-- If there's no discount, show regular price -->
                <ng-template #regularMultiplePrice >
                  <span>{{getPricing(product.prices, product.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{getPricing(product.prices, product.pricingPeriodId, 'nameTranslated')}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(product.prices, product.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
                </ng-template>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <!-- If there's a discount available for the item, show it -->
                <ng-container *ngIf="product.discountprices && getDiscountPricing(product.discountprices, item.id, 'value') as discountedPrice; else regularMultiplePrice">
                  <span class="font-weight-bold">
                    {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                  </span>
                  <span class="text-danger line-through font-italic">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
                </ng-container>
  
                <!-- If there's no discount, show regular price -->
                <ng-template #regularMultiplePrice >
                  <span class="font-weight-bold">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                  <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
                </ng-template>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <ng-container *ngIf="c.controls.length > 0">
          <div class="row pt-2 pb-2 align-items-center"
               *ngFor="let configOption of c.controls; let i = index; let last= last;">
            <div class="col-lg-6 mb-1 mb-lg-0">
              <div class="row" [formGroup]="configOption">
                <input type="hidden" formControlName="id"/>
                <h6>
                  <span class="col-lg-12 col-sm-6">{{configOption.value.label}}</span>
                  <span class="col-sm-6 d-lg-none mb-3 font-weight-bold fsmaller" i18n>{{configOption.value.slider}}</span>
                </h6>
                <div class="col-12">
                  <ngx-slider formControlName="value" (userChangeEnd)="updateCfgOption($event, configOption)" [options]="configOption.value.ticks"></ngx-slider>
                </div>
              </div>
            </div>
            <div class="col-lg-3 mb-3 mb-lg-0 font-weight-bold d-none d-lg-flex">{{configOption.value.slider}}</div>
            <div class="col-lg-3">
              <div class="row mt-sm-3">
                <span class="col-lg-12 btn btn-bordered rounded-light btn-sm w-100 bg-white"><!-- Without discount -->
                  <ng-container *ngIf="!configOption.value.discountPrice">
                    {{configOption.value.price | currency: cartCurrency.code : 'symbol-narrow'}}
                    <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                      ({{((configOption.value.price)*7.53450) | number: '1.2-2'}} HRK)
                    </span>
                  </ng-container>
      
                  <!-- With discount -->
                  <ng-container *ngIf="configOption.value.discountPrice > 0">
                    {{configOption.value.discountPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                    <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                      ({{(configOption.value.discountPrice*7.53450) | number: '1.2-2'}} HRK)
                    </span>
      
                    <span class="text-danger line-through font-italic">
                      {{configOption.value.price | currency: cartCurrency.code : 'symbol-narrow'}}
                      <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                        ({{((configOption.value.price)*7.53450) | number: '1.2-2'}} HRK)
                      </span>
                    </span>
                  </ng-container>
                </span>
              </div>
            </div>
            <hr class="col-12 d-lg-none" *ngIf="!last">
          </div>
        </ng-container>

        <div class="row mb-3 pt-2">
          <div class="col-10 col-md-6 mb-3 mb-md-0">
          <ng-container *ngIf="cu.controls.length > 0">
            <ng-container *ngFor="let customField of cu.controls; let i = index; let first = first;">
              <div class="row form-group align-items-center" [formGroup]="customField" [class.pt-3]="first">
                <label for="customField_{{i}}" class="col-12">{{customField.value.label}}</label>
                <div class="col-12 input-group">
                  <input type="text" formControlName="value" id="customField_{{i}}" class="form-control" [ngClass]="{ 'is-invalid': submitted && customField.get('value').errors }">
                  <div *ngIf="submitted && customField.get('value').errors" class="invalid-feedback w-100">
                    <div *ngIf="customField.get('value').errors.required" i18n>{{customField.value.label}} je obavezno polje</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          </div>
        </div>

        <div class="row pt-3 pb-3 bg-white align-items-center">
          <div class="col-12 col-sm-6 col-lg-9 mb-3 mb-sm-0">
            <h6 i18n>ZA PLATITI ODMAH</h6>
          </div>
          <div class="col-12 col-sm-6 col-lg-3">
            <span class="btn btn-bordered rounded-light btn-sm w-100 font-weight-bold bg-white">
              <!-- Without discount -->
              <ng-container *ngIf="!f.totalDiscountPrice.value">
                {{f.setupPrice.value * f.quantity.value + f.totalPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                  ({{((f.setupPrice.value * f.quantity.value + f.totalPrice.value)*7.53450) | number: '1.2-2'}} HRK)
                </span>
              </ng-container>
  
              <!-- With discount -->
              <ng-container *ngIf="f.totalDiscountPrice.value > 0">
                {{f.setupPrice.value * f.quantity.value + f.totalDiscountPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                  ({{((f.setupPrice.value * f.quantity.value + f.totalDiscountPrice.value)*7.53450) | number: '1.2-2'}} HRK)
                </span>
  
                <span class="text-danger line-through font-italic">
                  {{f.setupPrice.value * f.quantity.value + f.totalPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}
                  <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                    ({{((f.setupPrice.value * f.quantity.value + f.totalPrice.value)*7.53450) | number: '1.2-2'}} HRK)
                  </span>
                </span>
              </ng-container>
            </span>
          </div>
        </div>
        <div class="row pt-4 pb-2 bg-grey align-items-center font-italic mb-4">
          <div class="col-12">
            <span i18n>Cijena ne uključuje PDV. </span>
          </div>
        </div>

        <div class="row d-flex justify-content-between">
          <div class="col-12 col-sm-5 mb-3 mb-sm-0">
            <button class="btn bg-color1 w-100" type="submit" (click)="updateCart(true)" i18n>+ Dodaj novi backup</button>
          </div>
          <div class="col-12 col-sm-5 mb-3 mb-sm-0">
            <button class="btn btn-primary w-100" type="submit" (click)="updateCart()" i18n>Nastavi</button>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>

<div class="p-3">
  <app-loader *ngIf="loading.product"></app-loader>
</div>
