<ng-container *ngIf="productId && product && (c.controls.length > 0 || cu.controls.length > 0); else chooseProduct">
  <form [formGroup]="licenceGroupForm" (ngSubmit)="updateCart()">
    <div class="d-flex p-3 mb-3 bg-color1 align-items-center">
      <a routerLink="{{returnToOptionCid === null ? '/cart' : '../options/' + returnToOptionCid}}" [queryParams]="{gid: productGroup.id}"
         tooltip="Nazad na odabir proizvoda" i18n-tooltip="Nazad na odabir proizvoda"
         class="btn btn-outline-dark color-white rounded-light mr-3"><</a>
      <h3 class="color-white"><span i18n>Detaljne opcije za</span> {{getName(product)}}</h3>
    </div>

    <div class="container-fluid">
      <ng-container *ngIf="c.controls.length > 0">
        <div class="row d-none d-lg-flex justify-content-end font-weight-bold mb-3">
          <div class="col-2 pt-1" i18n>Količina</div>
          <div class="col-2 pt-1" i18n>Cijena</div>
        </div>

        <div class="row pt-2 pb-2 mb-3 mb-lg-0 align-items-center" *ngFor="let configOption of c.controls; let i = index; let last = last;">
          <div class="col-12 col-lg-8 mb-3 mb-lg-0">
            <div [formGroup]="configOption">
              <input type="hidden" formControlName="id"/>
              <h6 class="mb-4">{{configOption.value.label}}</h6>
              <ngx-slider formControlName="value" (userChangeEnd)="updateCfgOption($event, configOption)"
                          [options]="configOption.value.ticks"></ngx-slider>
            </div>
          </div>

          <div class="col-12 col-lg-2 mb-3 mb-lg-0 font-weight-bold">
            <div class="row">
              <span class="col-3 d-lg-none" i18n>Količina</span>
              <span class="col-9 col-lg-12">{{configOption.value.slider}}</span>
            </div>
          </div>

          <div class="col-12 col-lg-2">
            <div class="row">
              <span class="col-3 d-lg-none" i18n>Cijena</span>
              <div class="col-9 col-lg-12">
                <span class="btn btn-bordered rounded-light btn-sm w-100 font-weight-bold">
                  <!-- Without discount -->
                  <ng-container *ngIf="!configOption.value.discountPrice">
                    {{configOption.value.price | currency: cartCurrency.code : 'symbol-narrow'}}
                    <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                      ({{((configOption.value.price)*7.53450) | number: '1.2-2'}} HRK)
                    </span>
                  </ng-container>
      
                  <!-- With discount -->
                  <ng-container *ngIf="configOption.value.discountPrice > 0">
                    {{configOption.value.discountPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                    <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                      ({{(configOption.value.discountPrice*7.53450) | number: '1.2-2'}} HRK)
                    </span>
      
                    <span class="text-danger line-through font-italic">
                      {{configOption.value.price | currency: cartCurrency.code : 'symbol-narrow'}}
                      <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                        ({{((configOption.value.price)*7.53450) | number: '1.2-2'}} HRK)
                      </span>
                    </span>
                  </ng-container>
                </span>
              </div>
            </div>
          </div>
          <hr *ngIf="!last">
        </div>
      </ng-container>

      <ng-container *ngIf="cu.controls.length > 0">
        <div class="row form-group mb-3" [formGroup]="customField" *ngFor="let customField of cu.controls; let i = index;">
          <input type="hidden" formControlName="id"/>
          <input type="hidden" formControlName="value"/>
          <div class="col-12 col-md-3 col-xl-2 font-weight-bold">{{customField.value.label}}</div>
          <div class="col-12 col-md-9 col-xl-10 d-flex input-group" [ngClass]="{ 'is-invalid': submitted && customField.get('value').errors, 'mb-3': customField.value.type === 'password'}">
            <input #inputElement id="{{customField.value.id}}" [type]="shownFields[customField.value.id] ? customField.value.type : 'text'" value="{{customField.value.value}}"
                   (input)="setCustomFieldValue(inputElement.value, customField)" class="form-control"/>
            <div *ngIf="customField.value.type === 'password'" class="input-group-append toggable">
              <div class="input-group-text" (click)="shownFields[customField.value.id] = !shownFields[customField.value.id];">
                <fa-icon tooltip="Vidi lozinku" i18n-tooltip="Vidi lozinku" [icon]="faEyeSlash" *ngIf="!shownFields[customField.value.id]"></fa-icon>
                <fa-icon tooltip="Sakrij lozinku" i18n-tooltip="Sakrij lozinku" [icon]="faEye" *ngIf="shownFields[customField.value.id]"></fa-icon>
              </div>
            </div>
          </div>
          <div *ngIf="submitted && customField.get('value').errors" class="col-12 col-md-9 col-xl-10 offset-md-3 offset-xl-2 invalid-feedback w-100">
            <div *ngIf="customField.get('value').errors.required" i18n>{{customField.value.label}} je obavezno polje</div>
            <div *ngIf="customField.get('value').errors.minlength" i18n>{{customField.value.label}} mora sadržavati najmanje 8 znakova</div>
            <div *ngIf="customField.get('value').errors.maxlength" i18n>{{customField.value.label}} mora sadržavati najviše 16 znakova</div>
            <div *ngIf="customField.get('value').errors.pattern">{{customField.value.label}} mora sadržavati najmanje jedno veliko slovo, jedan broj i jedan posebni znak</div>
          </div>

          <div class="col-12 col-md-9 col-xl-10 offset-md-3 offset-xl-2">
            <button class="w-100 btn rounded-light btn-outline-info" type="button" *ngIf="customField.value.type === 'password'" (click)="generatePassword(customField)" i18n>Kreiraj novu lozinku</button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="product?.showdomainoptions === 1">
        <div class="bg-grey mb-3 p-3 shadow">
          <div class="form-check">
            <input id="domainNew" type="radio" formControlName="domainType" value="register" class="form-check-input"/>
            <label for="domainNew" class="form-check-label" i18n>Želim registrirati novu domenu</label>
          </div>
          <p i18n>Ukoliko nemaš domenu ili trebaš novu, odaberi ovu opciju.</p>
        </div>

        <div class="pl-3 pl-lg-5 mb-3" *ngIf="f.domainType.value === 'register'">
          <app-product-domain [filter]="'available'" [isIncluded]="true"></app-product-domain>

          <div class="form-group" *ngIf="domains?.length > 0">
            <label for="domain" class="form-check-label" i18n>Odaberi naziv glavne domene za hosting</label>
            <ng-select [items]="domains"
                       (change)="checkDomain()"
                       formControlName="domain"
                       bindValue="name"
                       bindLabel="name"
                       i18n-placeholder="Odaberi glavnu domenu"
                       placeholder="Odaberi glavnu domenu">
              <ng-template ng-label-tmp let-item="item">
                <span>{{getSelectedDomain('name') }}</span>&nbsp;-&nbsp;<ng-container *ngIf="getSelectedDomain('name')">({{getSelectedDomain('pricingPeriodId')}}&nbsp;<span i18n>godina</span>)</ng-container>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <span [ngClass]="{'font-weight-bold': item.cartProductId !== undefined}"><span >{{item.name }}</span>&nbsp;-&nbsp;({{item.pricingPeriodId}}&nbsp;<span i18n>godina</span>)</span>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="bg-grey mb-3 p-3 shadow">
          <div class="form-check">
            <input id="domainTransfer" type="radio" formControlName="domainType" value="transfer" class="form-check-input"/>
            <label for="domainTransfer" class="form-check-label" i18n>Želim prebaciti postojeću domenu s drugog registra na MyDataKnox</label>
          </div>
        </div>

        <div class="bg-grey mb-3 p-3 shadow">
          <div class="form-check bg-grey">
            <input id="domainOwn" type="radio" formControlName="domainType" value="renew" class="form-check-input"/>
            <label for="domainOwn" class="form-check-label" i18n>Imam vlastitu domenu i osobno ću izmjeniti nameservere</label>
          </div>
        </div>

        <div class="pl-5 mb-3" *ngIf="f.domainType.value !== 'register'">
          <p i18n>Ako već posjeduješ domenu i želiš ju iskoristiti za povezivanje s hosting paketom, odaberi ovu opciju. Kako bi domena prikazivala sadržaj s hosting paketa, potrebno je nakon aktivacije hosting paketa izmijeniti DNS podatke za domenu tako da upućuje na Mydataknox nameservere. Detaljne upute o usmjerenju naći ćeš u bazi znanja.</p>
          <div class="form-group">
            <label for="domain" class="col-form-label" i18n>Upiši naziv domene:</label>
            <input id="domain" type="text" formControlName="domain" placeholder="domena.com" class="form-control"/>
          </div>
        </div>

        <div *ngIf="submitted && f.domain.errors" class="alert alert-danger">
          <div *ngIf="f.domain.errors.required" i18n>Domena obavezna</div>
          <div *ngIf="f.domain.errors.minlength" i18n>Domena mora imati minimum 3 znaka</div>
          <div *ngIf="f.domain.errors.pattern" i18n>Neispravan format domene</div>
        </div>
      </ng-container>

      <div class="row bg-grey bd-bottom mb-4 pt-2 pb-2 align-items-center">
        <div class="col-8">
          <h6 class="font-weight-bold" i18n>Ukupna cijena (bez PDV-a)</h6>
        </div>
        <div class="col-4">
          <span class="btn btn-bordered rounded-light btn-sm width-1 font-weight-bold">
            <!-- Without discount -->
            <ng-container *ngIf="!f.totalDiscountPrice.value">
              {{f.totalPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                ({{(f.totalPrice.value*7.53450) | number: '1.2-2'}} HRK)
              </span>
            </ng-container>

            <!-- With discount -->
            <ng-container *ngIf="f.totalDiscountPrice.value > 0">
              {{f.totalDiscountPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}
              <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                ({{(f.totalDiscountPrice.value*7.53450) | number: '1.2-2'}} HRK)
              </span>

              <span class="text-danger line-through font-italic">
                {{f.totalPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                  ({{(f.totalPrice.value*7.53450) | number: '1.2-2'}} HRK)
                </span>
              </span>
            </ng-container>
          </span>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button class="btn btn-green col-3" type="submit" i18n>Nastavi</button>
    </div>
  </form>
</ng-container>

<ng-template #chooseProduct>
  <div class="d-flex align-items-center p-3 p-tb-sm bg-color1 mb-0">
    <a routerLink="{{returnToOptionCid === null ? '/cart' : '../options/' + returnToOptionCid}}" tooltip="Natrag na odabir proizvoda" i18n-tooltip="Natrag na odabir proizvoda"
       class="color-white btn btn-outline-light rounded-light mr-3"><</a>
    <h3 class="color-white">{{getGroupName()}}</h3>
  </div>

  <div class="container-fluid bg-white">
    <div class="row bd-bottom pt-2 pb-2 align-items-center" *ngFor="let licenceGroupProduct of licenceGroupProducts"
         [ngClass]="licenceGroupProduct.added ? 'bg-color8' : ''">
      <div class="col-12 col-lg-7 mb-3 mb-lg-0">
        <p class="font-weight-bold">{{getProductTranslatedName(licenceGroupProduct)}}</p>
        <p class="fsmall">{{getDescription(licenceGroupProduct)}}</p>
      </div>

      <div class="col-12 col-sm-6 col-lg-2 mb-3 mb-sm-0">
        <!-- If there's only one type of prices, show a simple pricing -->
        <ng-container *ngIf="licenceGroupProduct.prices.length === 1; else dropdownPrices">
          <!-- Check if there are discounts available -->
          <p *ngIf="licenceGroupProduct.discountprices?.length === 1">
            <span class="font-weight-bold">
              {{licenceGroupProduct.discountprices?.[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
            </span>
            <span class="text-danger line-through font-italic">
              {{licenceGroupProduct.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
            </span>
            <span>&nbsp;/&nbsp;{{licenceGroupProduct.discountprices?.[0].nameTranslated}}</span>
          </p>

          <!-- If there are no discounts available, show regular price -->
          <p *ngIf="licenceGroupProduct.discountprices?.length === 0">
            <span class="font-weight-bold">{{licenceGroupProduct.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
            <span>&nbsp;/&nbsp;{{licenceGroupProduct.prices[0].nameTranslated}}</span>
            <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(licenceGroupProduct.prices[0].value*7.53450) | number: '1.2-2'}} HRK)</span>
          </p>
        </ng-container>

        <!-- If there are multiple prices available, show select component -->
        <ng-template #dropdownPrices>
          <ng-select [items]="licenceGroupProduct.prices"
                     [searchable]="false"
                     [clearable]="false"
                     [(ngModel)]="licenceGroupProduct.pricingPeriodId"
                     class="w-100"
                     bindValue="id"
                     bindLabel="name"
                     i18n-placeholder="Odaberi period"
                     placeholder="Odaberi period">
            <ng-template ng-label-tmp let-item="item">
              <!-- If there's a discount available for the item, show it -->
              <ng-container *ngIf="licenceGroupProduct.discountprices && getDiscountPricing(licenceGroupProduct.discountprices, licenceGroupProduct.pricingPeriodId, 'value') as discountedPrice; else regularMultiplePrice">
                <span>
                  {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                </span>
                <span class="text-danger line-through font-italic">{{getPricing(licenceGroupProduct.prices, licenceGroupProduct.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{getPricing(licenceGroupProduct.prices, licenceGroupProduct.pricingPeriodId, 'nameTranslated')}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(licenceGroupProduct.prices, licenceGroupProduct.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
              </ng-container>

              <!-- If there's no discount, show regular price -->
              <ng-template #regularMultiplePrice >
                <span>{{getPricing(licenceGroupProduct.prices, licenceGroupProduct.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{getPricing(licenceGroupProduct.prices, licenceGroupProduct.pricingPeriodId, 'nameTranslated')}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(licenceGroupProduct.prices, licenceGroupProduct.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
              </ng-template>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <!-- If there's a discount available for the item, show it -->
              <ng-container *ngIf="licenceGroupProduct.discountprices && getDiscountPricing(licenceGroupProduct.discountprices, item.id, 'value') as discountedPrice; else regularMultiplePrice">
                <span class="font-weight-bold">
                  {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                </span>
                <span class="text-danger line-through font-italic">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
              </ng-container>

              <!-- If there's no discount, show regular price -->
              <ng-template #regularMultiplePrice >
                <span class="font-weight-bold">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
              </ng-template>
            </ng-template>
          </ng-select>
        </ng-template>
      </div>

      <div class="col-12 col-sm-6 col-lg-3">
        <button *ngIf="!licenceGroupProduct.stockcontrol || (licenceGroupProduct.stockcontrol && licenceGroupProduct.stocklevel > 0)"
                class="btn rounded-light btn-green btn-sm w-100 h-select"
                [ngClass]="licenceGroupProduct.added ? 'btn-active' : ''" (click)="setProduct(licenceGroupProduct)">
          <svg-icon src="assets/svg/shop-white.svg" class="icon icon-15 icon-shop mr-2"></svg-icon>
          <span *ngIf="!licenceGroupProduct.added; else removeCartItem;" i18n>Dodaj u košaricu</span>
          <ng-template #removeCartItem>
            <span i18n>Ukloni iz košarice</span>
          </ng-template>
        </button>

        <button *ngIf="licenceGroupProduct.stockcontrol && licenceGroupProduct.stocklevel <= 0"
                [disabled]="true"
                class="btn btn-bordered rounded-light btn-sm w-100 h-select">
          <span i18n>Trenutno nedostupno</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div class="p-3 text-center">
  <app-loader *ngIf="loading.product"></app-loader>
</div>
