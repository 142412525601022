import {Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {AuthenticationService, ClientService} from '../../_services';
import {Affiliate} from '../../_models/affiliate';
import {Pager} from '../../support/tickets/pager';
import {Currency} from '../../_models/currency';
import {Client} from '../../_models';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: 'profile-affiliate.component.html'
})
export class ProfileAffiliateComponent implements OnInit, OnDestroy {
  @Input() pageSize = 10;
  subscription = new Subscription();
  affiliateLink: string;
  affiliateActive = false;
  affiliate: Affiliate;
  paginatedCommissionHistory: Pick<Affiliate, 'commissionhistory'>;
  pager: Pager;
  loading = false;
  currentCurrency: Currency;
  withdraw = false;
  total = {
    payamount: 0,
    balance: 0
  };
  commissionHistoryPager: Pager;
  client: Client;
  public displayHrk = false;

  constructor(
    private service: ClientService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) public config: AppConfig,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    this.client = this.authenticationService.currentUserValue;
    this.currentCurrency = this.authenticationService.currentCurrencyValue;
  }

  ngOnInit(): void {
    this.displayHrk = this.config.displayHrk;

    this.subscription.add(
      this.authenticationService.getUserDetails()
        .subscribe(user => {
            if (user) {
              this.client.affiliateid = user.affiliateid !== undefined ? user.affiliateid : this.client.affiliateid;
              this.authenticationService.updateUser(this.client);
              this.loadAffiliates();
            }
          }
        )
    );
  }

  loadAffiliates() {
    if (this.client.affiliateid) {
      this.loading = true;
      const affilitateSub = this.service.getAffiliates(this.client.userid, this.commissionHistoryPager)
        .pipe(take(1))
        .subscribe((res) => {
          this.loading = false;
          if (res) {
            this.affiliateActive = true;
            this.affiliate = res.affiliates;
            this.commissionHistoryPager = res.commissionHistoryPager;
            this.paginatedCommissionHistory = res.paginatedCommissionHistory;
            this.affiliateLink = `${environment.wpLink}/?affId=${this.affiliate.affiliateid}`;
          }
        });
      this.subscription.add(affilitateSub);
    }
  }

  public onCommissionHistoryPageChange(newPage: number): void {
    this.commissionHistoryPager.currentPage = newPage;
    this.loadAffiliates();
  }

  copyLink(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getServiceName(service) {
    let name = (typeof service.name !== 'undefined') ? service.name : null;
    const translations = (typeof service.translation !== 'undefined' && typeof service.translation !== 'undefined'
      && typeof service.translation.translated_product_name !== 'undefined') ? service.translation.translated_product_name : null;
    name = (typeof translations[this.localeId] !== 'undefined') ? translations[this.localeId] : name;
    return name;
  }

  withdrawBalance() {
    this.withdraw = true;
  }

  getFaqLink() {
    return `${this.config.affiliateFaqLink}`;
  }

  activateAffiliate() {
    this.loading = true;
    const activateAffilitateSub = this.service.activateAffiliate()
      .pipe(take(1))
      .subscribe(res => {
        this.loading = false;
        if (res) {
          this.affiliateActive = true;
        }
      });

    this.subscription.add(activateAffilitateSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
