<form [formGroup]="sslForm" (ngSubmit)="updateCart()">
  <ng-container *ngIf="product; else chooseProduct">
    <div class="d-flex p-3 bg-color1 align-items-center">
      <a routerLink="./" [queryParams]="{gid: productGroup.id}" tooltip="Na listu SSL proizvoda" i18n-tooltip="Na listu SSL proizvoda"
         class="color-white btn btn-outline-light rounded-light mr-3"><</a>
      <h3 class="color-white"><span i18n>SSL - odabir domene za</span> {{getName(product)}}</h3>
    </div>

    <div class="p-3">
      <div class="form-group" [formGroup]="domainGroup">
        <label for="domainselect" class="form-check-label" i18n>Odaberi ili upiši domenu za SSL</label>
        <ng-select [items]="domains"
                   [addTag]="addTagFn"
                   [hideSelected]="true"
                   (change)="checkDomain(changeDomainProduct)"
                   formControlName="value"
                   id="domainselect"
                   class="mb-2"
                   bindValue="name"
                   bindLabel="name"
                   i18n-placeholder="Odaberi glavnu domenu"
                   placeholder="Odaberi glavnu domenu">
          <ng-template ng-tag-tmp let-search="searchTerm">
            <b i18n>Dodaj</b>: {{search}}
          </ng-template>
          <ng-template ng-label-tmp let-item="item">
            <span>{{d.get('value').value}}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <span [ngClass]="{'font-weight-bold': item.cartProductId !== undefined}"><span>{{item.name }}</span><span *ngIf="item.pricingPeriodId"> &nbsp;-&nbsp;({{item.pricingPeriodId}}&nbsp;<span i18n>godina</span>)</span></span>
          </ng-template>
        </ng-select>
        <div class="alert alert-warning" *ngIf="submitted && d.get('value').errors">
          <p *ngIf="d.get('value').errors.pattern" i18n>Neispravan format domene</p>
          <p *ngIf="d.get('value').errors.required" i18n>Unos domene je obavezan</p>
        </div>
      </div>

      <ng-container>
        <div *ngFor="let customField of cu.controls; let i = index;">
          <ng-container *ngIf="showCustomField(customField.get('id').value)">
            <div class="form-group" [formGroup]="customField">
              <input type="hidden" formControlName="id"/>
              <input type="hidden" formControlName="value"/>
              <p class="font-weight-bold">{{customField.value.label}}</p>
              <div class="d-flex input-group" [ngClass]="{ 'is-invalid': submitted && customField.get('value').errors }">
                <input #inputElement id="{{customField.value.id}}" type="text" value="{{customField.value.value}}"
                       (input)="setCustomFieldValue(inputElement.value, customField)" class="form-control"/>
              </div>
              <div *ngIf="submitted && customField.get('value').errors" class="invalid-feedback w-100">
                <div *ngIf="customField.get('value').errors.required" >{{customField.value.label}} <span i18n>je obavezno polje</span>
                  <div *ngIf="customField.get('value').errors.pattern">{{customField.value.label}} <span i18n>je neispravnog formata</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-grey bd-bottom">
              <h6 class="font-weight-bold" i18n>Ukupna cijena (bez PDV-a)</h6>
              <p>
                <span class="btn btn-bordered rounded-light btn-sm w-100 font-weight-bold">
                  <!-- Without discount -->
                  <ng-container *ngIf="!f.totalDiscountPrice.value">
                    {{f.totalPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}
                    <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                      ({{(f.totalPrice.value*7.53450) | number: '1.2-2'}} HRK)
                    </span>
                  </ng-container>

                  <!-- With discount -->
                  <ng-container *ngIf="f.totalDiscountPrice.value > 0">
                    {{f.totalDiscountPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}
                    <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                      ({{(f.totalDiscountPrice.value*7.53450) | number: '1.2-2'}} HRK)
                    </span>

                    <span class="text-danger line-through font-italic">
                      {{f.totalPrice.value | currency: cartCurrency.code : 'symbol-narrow'}}
                      <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">
                        ({{(f.totalPrice.value*7.53450) | number: '1.2-2'}} HRK)
                      </span>
                    </span>
                  </ng-container>
                </span>
              </p>
            </div>
          </ng-container>
      </div>
      </ng-container>
      <div class="d-flex justify-content-end">
        <button class="btn btn-green width-13" type="submit" i18n>Nastavi</button>
      </div>
    </div>

  </ng-container>
</form>

<ng-template #chooseProduct>
  <div class="d-flex align-items-center p-3 p-tb-sm bg-color1 mb-0">
    <a routerLink="{{returnToOptionCid === null ? '/cart' : '../options/' + returnToOptionCid}}" tooltip="Natrag na odabir proizvoda" i18n-tooltip="Natrag na odabir proizvoda"
       class="color-white btn btn-outline-light rounded-light mr-3"><</a>
    <h3 class="color-white">{{getGroupName()}}</h3>
  </div>
  <div class="container-fluid bg-white">
    <div class="row pt-2 pb-2 bd-bottom" *ngFor="let sslProduct of sslProducts" [ngClass]="sslProduct.added ? 'bg-color8' : ''">
      <div class="col-lg-7 mb-3 mb-lg-0">
        <p class="font-weight-bold">{{getProductTranslatedName(sslProduct)}}</p>
        <p class="fsmall">{{getDescription(sslProduct)}}</p>
      </div>

      <div class="col-sm-6 col-lg-2 mb-3 mb-sm-0">
        <ng-container *ngIf="sslProduct.prices.length === 1; else dropdownPrices">
          <!-- Check if there are discounts available -->
          <p *ngIf="sslProduct.discountprices?.length === 1">
            <span class="font-weight-bold">
              {{sslProduct.discountprices?.[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
            </span>
            <span class="text-danger line-through font-italic">
              {{sslProduct.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}
            </span>
            <span>&nbsp;/&nbsp;{{sslProduct.discountprices?.[0].nameTranslated}}</span>
          </p>

          <!-- If there are no discounts available, show regular price -->
          <p *ngIf="sslProduct.discountprices?.length === 0">
            <span class="font-weight-bold">{{sslProduct.prices[0].value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
            <span>&nbsp;/&nbsp;{{sslProduct.prices[0].nameTranslated}}</span>
            <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(sslProduct.prices[0].value*7.53450) | number: '1.2-2'}} HRK)</span>
          </p>
        </ng-container>
        
        <ng-template #dropdownPrices>
          <ng-select [items]="sslProduct.prices"
                     [searchable]="false"
                     [clearable]="false"
                     [(ngModel)]="sslProduct.pricingPeriodId"
                     class="width-200"
                     bindValue="id"
                     bindLabel="name"
                     i18n-placeholder="Odaberi period"
                     placeholder="Odaberi period">
            <ng-template ng-label-tmp let-item="item">
              <!-- If there's a discount available for the item, show it -->
              <ng-container *ngIf="sslProduct.discountprices && getDiscountPricing(sslProduct.discountprices, sslProduct.pricingPeriodId, 'value') as discountedPrice; else regularMultiplePrice">
                <span>
                  {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                </span>
                <span class="text-danger line-through font-italic">{{getPricing(sslProduct.prices, sslProduct.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{getPricing(sslProduct.prices, sslProduct.pricingPeriodId, 'nameTranslated')}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(sslProduct.prices, sslProduct.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
              </ng-container>

              <!-- If there's no discount, show regular price -->
              <ng-template #regularMultiplePrice >
                <span>{{getPricing(sslProduct.prices, sslProduct.pricingPeriodId, 'value') | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{getPricing(sslProduct.prices, sslProduct.pricingPeriodId, 'nameTranslated')}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{(getPricing(sslProduct.prices, sslProduct.pricingPeriodId, 'value')*7.53450) | number: '1.2-2'}} HRK)</span>
              </ng-template>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <!-- If there's a discount available for the item, show it -->
              <ng-container *ngIf="sslProduct.discountprices && getDiscountPricing(sslProduct.discountprices, item.id, 'value') as discountedPrice; else regularMultiplePrice">
                <span class="font-weight-bold">
                  {{discountedPrice | currency: cartCurrency.code : 'symbol-narrow'}}
                </span>
                <span class="text-danger line-through font-italic">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
              </ng-container>

              <!-- If there's no discount, show regular price -->
              <ng-template #regularMultiplePrice >
                <span class="font-weight-bold">{{item.value | currency: cartCurrency.code : 'symbol-narrow'}}</span>
                <span>&nbsp;/&nbsp;{{item.nameTranslated}}</span>
                <span *ngIf="cartCurrency.code === 'EUR' && displayHrk">({{item.value*7.53450 | number: '1.2-2'}} HRK)</span>
              </ng-template>
            </ng-template>
          </ng-select>
        </ng-template>
      </div>

      <div class="col-sm-6 col-lg-3">
        <button *ngIf="!sslProduct.stockcontrol || (sslProduct.stockcontrol && sslProduct.stocklevel > 0)"
                class="btn btn-bordered rounded-light btn-green btn-sm h-select w-100"
                [ngClass]="sslProduct.added ? 'btn-active' : ''" (click)="setProduct(sslProduct)">
          <svg-icon src="assets/svg/shop-white.svg" class="icon icon-15 icon-shop mr-2"></svg-icon>
          <span *ngIf="!sslProduct.added; else removeCartItem;" i18n>Dodaj u košaricu</span>
          <ng-template #removeCartItem>
            <span i18n>Ukloni iz košarice</span>
          </ng-template>
        </button>

        <button *ngIf="sslProduct.stockcontrol && sslProduct.stocklevel <= 0"
                [disabled]="true"
                class="btn btn-bordered rounded-light btn-sm w-100 h-select">
          <span i18n>Trenutno nedostupno</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div class="p-3">
  <app-loader *ngIf="loading.product"></app-loader>
</div>

<ng-template #changeDomainProduct class="modal modal-sm">
  <div class="modal-header d-flex justify-content-between">
    <h3>Domena povezana</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p i18n>Domena</p>
    <p class="font-weight-bold" i18n>{{ getSelectedDomain('name')}}</p>
    <p class="mb-3"  i18n>je već povezana na proizvod</p>
    <p class="mb-3" *ngIf="connectedCartItem"><a routerLink="./product" [queryParams]="{'cid': connectedCartItem.cartId}"></a> {{connectedCartItem.name}}</p>
<!--    <p class="fsmaller" i18n>Nastavkom ukidate vezu na prijašnji proizvod!</p>-->
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-primary rounded" *ngIf="!submitted" aria-label="Close" (click)="modalRef.hide()" i18n>Zatvori</button>
  </div>
</ng-template>
