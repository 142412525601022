<div class="bd-bottom p-3 bg-white">
  <h4 class="font-weight-bold text-color-2 pl-2" i18n>Akcije</h4>
</div>

<div class="p-3">
  <div class="container-fluid">
    <ng-container *ngIf="!loading && discountPost?.length">
      <div class="row bg-white p-3 border rounded-light shadow mb-4" *ngFor="let discount of discountPost">
        <div class="col-12 col-sm-7 mb-3 mb-sm-0">
          <a appExternalUrl class="text-underline" [href]="discount.actionUrl" *ngIf="discount.actionUrl !== ''"><h4>{{discount.title}}</h4></a>
          <h4 *ngIf="discount.actionUrl === ''">{{discount.title}}</h4>
          <hr>
          <div [innerHTML]="discount.content"></div>
        </div>

        <div class="col-12 col-sm-5">
          <img class="rounded-light mb-2" [src]="discount.thumbnail" *ngIf="discount.thumbnail.length > 0">
          <div class="font-weight-bold mb-3 color2">
            <span i18n>Trajanje akcije</span>:&nbsp;
            {{discount.actionValidFrom | date: 'dd.MM.yyyy.'}}&nbsp;-&nbsp;{{discount.actionValidTo | date: 'dd.MM.yyyy.'}}
          </div>
          <div class="font-weight-bold border p-2 boder-dashed text-center flarger color4">
            <span class="text-uppercase d-inline-block" i18n>Promo kod</span>:&nbsp;{{discount.actionPromoCode}}</div>
        </div>
      </div>
    </ng-container>
    <div class="alert alert-info" *ngIf="!loading && !discountPost?.length" i18n>Nema novih akcija</div>
  </div>

  <app-loader *ngIf="loading"></app-loader>
</div>
