import {Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {of, Subscription} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {Options} from '@m0t0r/ngx-slider';
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';

import {Currency} from '../../../_models/currency';
import {CartService} from '../../cart.service';
import {ProductGroup} from '../../../_models/product-group';
import {ProductConfigOption} from '../../../_models/product-config-option';
import {Product} from '../../../_models/product';
import {SystemService} from '../../../_services/system.service';
import {ProductService} from '../../../_services/product.service';
import {CustomValidator} from '../../../_components';
import {CartOrderItem} from '../../cart-order-item';
import { APP_CONFIG, AppConfig } from 'src/app/app-config.module';

@Component({
  selector: 'app-product-dedicated-server',
  templateUrl: './product-dedicated-server.component.html'
})
export class ProductDedicatedServerComponent implements OnInit, OnDestroy {
  @Input() productGroup: ProductGroup;
  @Input() cartCurrency: Currency;
  @Input() configOptions: ProductConfigOption[];
  passPattern = '^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,16}$';
  productId: number;
  product: Product;
  dserverProducts: Product[] = [];
  dserverOses = [
    'windows',
    'linux'
  ];
  private subscription: Subscription = new Subscription();
  dserverForm: FormGroup;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  submitted = false;
  shown = true;
  loading = {
    product: false
  };
  os: string;
  hostnamePattern = '^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$';
  returnToOptionCid: number;
  public displayHrk = false;

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(APP_CONFIG) private config: AppConfig,
    private cartService: CartService,
    private systemService: SystemService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  get f() {
    return this.dserverForm.controls;
  }

  get c() {
    return this.f.config as FormArray;
  }

  get cu() {
    return this.f.customfields as FormArray;
  }

  ngOnInit() {
    this.displayHrk = this.config.displayHrk;

    if (!this.productGroup) {
      this.subscription.add(
        this.productService.getProductGroups()
          .pipe(take(1))
          .subscribe(groups => {
            this.productGroup = this.productService.getGroupByTag('dserver');
          })
      );
    }
    if (!this.cartCurrency) {
      this.subscription.add(
        this.cartService.getCartCurrency()
          .subscribe(currency => {
            this.cartCurrency = currency;
          })
      );
    }

    const routerQidSub = this.route.queryParams
      .pipe(switchMap(params => of(params)))
      .subscribe(params => {
        this.productId = (typeof params.pid !== 'undefined') ? parseInt(params.pid, 10) : null;
        const cartId = (typeof params.cid !== 'undefined') ? parseInt(params.cid, 10) : null;
        let pricingPeriodId = params.period !== undefined ? parseInt(params.period, 10) : null;
        this.returnToOptionCid = (typeof params.returnOption !== 'undefined') ? parseInt(params.returnOption, 10) : null;

        this.os = params.os !== undefined ? params.os : null;
        if (this.productId) {
          for (const cfgk in params) {
            if (params.hasOwnProperty(cfgk) && cfgk.indexOf('cfg') > -1) {
              let cfgId = cfgk.replace('cfg[', '');
              cfgId = cfgId.replace(']', '');
              const configOption = new ProductConfigOption();
              configOption.configoptionid = parseInt(cfgId, 10);
              configOption.value = parseInt(params[cfgk], 10);
              this.configOptions.push(configOption);
            }
          }

          const newPass = this.systemService.genRandomPattern(this.passPattern);
          const hostname = 'srv' + this.systemService.genRandomPattern('^([a-z0-9\\.\\-]){3,4}$');
          this.loading.product = true;
          const prodSub = this.productService.getProductById(this.productId, this.cartCurrency.code)
            .subscribe((product) => {
              this.loading.product = false;
              this.product = product;
              if (!pricingPeriodId) {
                const defaultPrice = (product.prices.length > 0) ? product.prices[0] : null;
                if (defaultPrice) {
                  pricingPeriodId = defaultPrice.id;
                }
              }
              this.product.pricingPeriodId = pricingPeriodId;
              this.dserverForm = this.formBuilder.group({
                cartId: [null],
                rootPassword: [newPass, [Validators.required, Validators.minLength(8),
                  Validators.maxLength(16),
                  Validators.pattern(this.passPattern)]],
                quantity: [1, [Validators.required, CustomValidator.numbericValidator]],
                pricingPeriod: [this.product.pricingPeriodId, Validators.required],
                basePrice: [null, Validators.required],
                setupPrice: [null, Validators.required],
                totalPrice: [null, Validators.required],
                totalDiscountPrice: [null],
                hostname: [hostname, [Validators.required, Validators.minLength(3),
                  Validators.pattern(this.hostnamePattern)]],
                config: new FormArray([]),
                customfields: new FormArray([])
              });

              // Add configoptions
              this.product.configoptions.forEach(option => {
                if (option.name.toUpperCase() !== 'OS TEMPLATE') {
                  let defVal = this.productService.getValueForOption(option, 'min');
                  let slider = this.productService.getValueForOption(option, 'name', defVal);
                  // Override prices for Unit options (they use values from minimum and maximum fields in option root
                  if (slider.toString().toUpperCase() === 'UNIT') {
                    slider = option.minimum;
                    defVal = option.minimum;
                  }
                  const ticks = this.productService.getOptionsForCFG(option);
                  const defOption = option.options[0];
                  const price = this.getPriceForOption(defOption);
                  if (this.configOptions.length > 0) {
                    const defaultOption = this.configOptions.find(x => x.configoptionid === option.id);
                    defVal = (defaultOption) ? defaultOption.value : defVal;
                  }
                  this.addOptionItem(option.id, defVal, option.name, slider, price, ticks);
                } else {
                  // Special case for option OS TEMPLATE
                  const allOptions = [];
                  option.options.forEach(os => {
                    allOptions.push(os.name);
                  });
                  let options = allOptions;
                  const filters = [];
                  allOptions.forEach(x => {
                    const filterName = x.substr(0, x.indexOf(' '));
                    if (filters.indexOf(filterName) < 0) {
                      filters.push(filterName);
                    }
                  });
                  const defOption = allOptions[0];
                  const defFilterName = defOption.substr(0, defOption.indexOf(' '));
                  if (defFilterName) {
                    options = options.filter(x => x.indexOf(defFilterName) >= 0);
                  }
                  this.addCustomFieldItem(option.id, defOption, option.name, options, allOptions, filters);
                }
              });

              // Add customfields
              this.product.customfields.forEach(option => {
                if (option.adminonly === '' && option.showorder === 'on') {
                  const allOptions = option.options.split(',').filter(x => x !== 'none');
                  let options = allOptions;
                  const filters = [];
                  allOptions.forEach(x => {
                    const filterName = x.substr(0, x.indexOf('-'));
                    if (filters.indexOf(filterName) < 0) {
                      filters.push(filterName);
                    }
                  });
                  const defOption = allOptions[0];
                  const defFilterName = defOption.substr(0, defOption.indexOf('-'));
                  if (defFilterName) {
                    options = options.filter(x => x.indexOf(defFilterName) >= 0);
                  }
                  this.addCustomFieldItem(option.id, defOption, option.name, options, allOptions, filters);
                }
              });
              if (cartId !== null) {
                this.setDefaultFromCart(cartId);
              }

              this.updateOptionPrices();
            });
          this.subscription.add(prodSub);
          // } else if (this.os) {
        } else {
          this.loading.product = true;
          const subA = this.productService.getProducts(null, null, this.productGroup.id, this.cartCurrency.code, true)
            .subscribe(products => {
              this.loading.product = false;
              // Filter products by OS
              if (this.os) {
                this.dserverProducts = products.filter(product => product.name.toUpperCase().indexOf(this.os.toUpperCase()) > 0);
              }
              this.setPricingDefaults();
              this.product = null;
            });
          this.subscription.add(subA);
        }
      });
    this.subscription.add(routerQidSub);
  }

  setPricingDefaults(): void {
    this.productService.setPricingDefaults(this.dserverProducts);
  }

  getGroupName(): string {
    return this.productService.getGroupNameByTag(this.productGroup.tag);
  }

  getDescription(dservProduct: Product): string {
    const description = this.productService.getDescription(dservProduct);
    if (description === '') {
      return this.productService.getDescription(dservProduct, true);
    }
    return description;
  }

  getPricing(prices: any, selectedId: number, field: string): number | string {
    return this.productService.getPricing(prices, selectedId, field);
  }

  getDiscountPricing(prices: any, selectedId: number, field: string): number|string {
    return this.productService.getDiscountPricing(prices, selectedId, field);
  }

  getProductName() {
    return this.product.translated_product_name[this.localeId] !== undefined ?
      this.product.translated_product_name[this.localeId] : this.product.name;
  }

  getProductTranslatedName(prod: Product) {
    if (prod.translated_product_name[this.localeId] !== undefined) {
      return prod.translated_product_name[this.localeId];
    }
    return prod.name;
  }

  addOptionItem(id: number = null, value: number = null, label: string = null, slider: string = null, price: number = null, ticks: Options)
    : void {
    this.c.push(this.formBuilder.group({
      id: [id, Validators.required],
      value: [value, Validators.required],
      label: [label],
      slider: [slider],
      price: [price],
      ticks
    }));
  }

  addCustomFieldItem(id: number = null, value: number | string = null, label: string = null,
                     options: string[], allOptions: string[], filters: string[]): void {
    this.cu.push(this.formBuilder.group({
      id: [id, Validators.required],
      value: [value, Validators.required],
      label: [label],
      options: [options],
      filters: [filters],
      allOptions: [allOptions]
    }));
  }

  viewInput() {
    this.shown = !this.shown;
  }

  getIconPath(os) {
    let path = '';
    if (os === null) {
      path = '';
    } else if (os.indexOf('none') >= 0) {
      path = '';
    } else if (os.indexOf('Centos') >= 0) {
      path = 'assets/svg/centos.svg';
    } else if (os.indexOf('Scientific') >= 0) {
      path = 'assets/svg/scientific_linux.svg';
    } else if (os.indexOf('Ubuntu') >= 0) {
      path = 'assets/svg/ubuntu-1.svg';
    } else if (os.indexOf('Fedora') >= 0) {
      path = 'assets/svg/fedora.svg';
    } else if (os.indexOf('Debian') >= 0) {
      path = 'assets/svg/debian.svg';
    } else if (os.indexOf('Windows') >= 0) {
      path = 'assets/svg/windows.svg';
    }
    return path;
  }

  setProduct(dservProduct: Product): void | boolean {
    this.router.navigate(['/cart/product'], {
      queryParams: {
        gid: dservProduct.gid,
        pid: dservProduct.pid,
        period: dservProduct.pricingPeriodId
      }
    });
  }

  /**
   * Gets current product pricing period name.
   */
  getPricingPeriodName(): string {
    if (this.f.pricingPeriod.value !== null) {
      return this.product.prices.find(p => p.id === this.f.pricingPeriod.value).nameTranslated;
    }
    return '';
  }

  getProductOs(product: Product) {
    for (const os of this.dserverOses) {
      if (product.name.toUpperCase().indexOf(os.toUpperCase()) > 0) {
        return os;
      }
    }
    return this.dserverOses[0];
  }

  getCurrentPeriodPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPrice = this.product.prices.find(x => x.id === this.f.pricingPeriod.value);
    if (field) {
      return currentPrice[field];
    }
    return currentPrice;
  }

  getCurrentPeriodDiscountPrice(field = 'value') {
    if (!this.product) {
      return false;
    }
    const currentPrice = this.product.discountprices?.find(x => x.id === this.f.pricingPeriod.value);
    return currentPrice ? currentPrice[field] : false;
  }

  getSetupPrice(field: string = null) {
    if (!this.product) {
      return false;
    }
    const currentPeriodPrice = this.getCurrentPeriodPrice();
    const feeSuffix = currentPeriodPrice.name.charAt(0);
    const productCurrencyFee = this.product.pricing[this.cartCurrency.code];
    if (field) {
      const fieldValue = parseFloat(productCurrencyFee[feeSuffix + field]);
      return (fieldValue < 0) ? 0 : fieldValue;
    }
    return productCurrencyFee;
  }

  getPriceForOption(option: any) {
    const prices = option.pricing[this.cartCurrency.code];
    const pricingPeriod = this.product.prices.find(x => x.id === this.f.pricingPeriod.value);
    const price = pricingPeriod !== undefined ? parseFloat(prices[pricingPeriod.name]) : 0;
    return (price <= 0) ? 0 : price;
  }

  getDiscountPriceForOption(option: any) {
    if (!option.discountpricing) {
      return 0;
    }
    const prices = option.discountpricing[this.cartCurrency.code];
    const pricingPeriod = this.product.discountprices?.find(x => x.id === this.f.pricingPeriod.value);
    const price = pricingPeriod !== undefined ? parseFloat(prices[pricingPeriod.name]) : 0;
    return (price <= 0) ? 0 : price;
  }

  updateOptionPrices() {
    let totalPrice = 0;
    let totalDiscountPrice = 0;

    const basePrice = this.getCurrentPeriodPrice('value');
    const discountBasePrice = this.getCurrentPeriodDiscountPrice('value');
    this.dserverForm.patchValue({basePrice});
    totalPrice += this.f.quantity.value * basePrice;
    totalDiscountPrice += this.f.quantity.value * discountBasePrice;
    this.dserverForm.patchValue({basePrice});
    const setupPrice = this.getSetupPrice('setupfee');
    this.dserverForm.patchValue({setupPrice});

    this.c.controls.forEach(cntrl => {
      const productConfig = this.product.configoptions.find(x => x.id === cntrl.get('id').value);
      let option = productConfig.options.find(x => x.id === cntrl.value.value);
      if (option === undefined) {
        option = productConfig.options[0];
      }
      // sliderValue option is used if slider value is in units (usually for ticksArray sliders)
      const sliderValue = option.name.toUpperCase() === 'UNIT' ? cntrl.value.value === null ? 0 : cntrl.value.value : 1;
      const price = this.f.quantity.value * this.getPriceForOption(option) * sliderValue;
      const discountPrice = this.f.quantity.value * this.getDiscountPriceForOption(option) * sliderValue;

      totalPrice += price;
      totalDiscountPrice += discountPrice;
      cntrl.get('price').setValue(price);
      cntrl.get('slider').setValue(option.name);
    });

    this.dserverForm.patchValue({totalPrice});
    this.dserverForm.patchValue({totalDiscountPrice});
  }

  setDefaultFromCart(cartId: number) {
    const cartItem: CartOrderItem = this.cartService.getCartItemByCartId(cartId);
    if (cartItem) {
      this.dserverForm.patchValue({cartId: cartItem.cartId});
      this.dserverForm.patchValue({rootPassword: cartItem.rootPassword});
      this.dserverForm.patchValue({hostname: cartItem.hostname});
      this.dserverForm.patchValue({pricingPeriod: cartItem.pricingPeriodId});
      this.dserverForm.patchValue({quantity: cartItem.quantity});
      cartItem.config.forEach(cfg => {
        const cfgControl = this.c.controls.find((x) => {
          return x.get('id').value === cfg.id;
        });
        cfgControl.get('value').setValue(cfg.value);
      });

      cartItem.customfields.forEach(cfg => {
        const cuControl = this.cu.controls.find((x) => {
          return x.get('id').value === cfg.id;
        });
        cuControl.get('value').setValue(cfg.value);
      });
    }
  }

  /**
   * Removes one quantity from current product
   * 1 is the lower limit
   */
  removeQuantity() {
    if (this.dserverForm.value.quantity < 2) {
      return false;
    }
    this.dserverForm.patchValue({quantity: --this.dserverForm.value.quantity});
    this.updateOptionPrices();
  }

  /**
   * Adds quantity to current product
   */
  addQuantity() {
    this.dserverForm.patchValue({quantity: ++this.dserverForm.value.quantity});
    this.updateOptionPrices();
  }

  updateCfgOption(id, cfg) {
    if (cfg.controls.ticks.value.stepsArray !== undefined) {
      const label = cfg.controls.ticks.value.stepsArray.find(x => x.value === id.value);
      cfg.controls.slider.setValue(label.legend);
    } else {
      const labelSuffix = cfg.controls.slider.value.toString().replace(/\d+/, '');
      cfg.controls.slider.setValue(id.value.toString() + labelSuffix);
    }
    this.updateOptionPrices();
  }

  generateRootPassword() {
    const newPass = this.systemService.genRandomPattern(this.passPattern);
    this.dserverForm.patchValue({rootPassword: newPass});
  }

  setCustomFieldValue(value: string, customFieldControl) {
    const options = customFieldControl.get('allOptions').value.filter(x => x.indexOf(value) >= 0);
    const defOption = options[0];
    customFieldControl.get('options').setValue(options);
    customFieldControl.get('value').setValue(defOption);
  }

  getProductConfigName() {
    return this.productService.getName(this.product);
  }

  getProductConfigDescription() {
    let desc = '';
    this.dserverForm.value.config.forEach((i, idx, cfg) => {
      const divider = ((idx + 1) < this.dserverForm.value.config.length) ? ', ' : '';
      desc += `${cfg[idx].label}: ${cfg[idx].slider}${divider}`;
    });
    desc = desc !== '' ? desc += ', ' : '';
    this.dserverForm.value.customfields.forEach((i, idx, cfg) => {
      const divider = ((idx + 1) < this.dserverForm.value.customfields.length) ? ', ' : '';
      desc += `${cfg[idx].label}: ${cfg[idx].value}${divider}`;
    });

    return desc;
  }

  updateCart(createNew: boolean = false) {
    this.submitted = true;
    if (this.dserverForm.invalid) {
      return false;
    }
    const name = this.getProductConfigName();
    let desc = this.getProductConfigDescription();
    desc = desc === '' ? this.getDescription(this.product) : desc;
    let cartItem = this.cartService.generateCartItem(this.product, this.productGroup, name, desc, this.dserverForm.value);
    cartItem = this.cartService.addOrderItem(cartItem);
    this.dserverForm.patchValue({cartId: cartItem.cartId});
    this.submitted = false;
    return (createNew) ?
      this.router.navigate(['/cart/product'], {queryParams: {gid: this.productGroup.id}}) :
      this.router.navigate([`/cart/options/${cartItem.cartId}`]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setPricingPeriod(price: { id: number, name: string }) {
    this.product.pricingPeriodId = price.id;
    this.updateOptionPrices();
  }
}
