<div class="container-fluid">
  <div class="row pt-1 pt-md-5 bg-background">
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 box p-2 p-md-3 p-lg-4">
      <h5 class="font-weight-bold mb-3 bd-bottom pb-3" i18n>Poništenje izgubljenje lozinke</h5>
      <p class="mb-3" i18n>Molimo unesite vašu novu željenu lozinku.</p>

      <form [formGroup]="newPasswordForm" (ngSubmit)="onSubmit()" *ngIf="nonce">
        <div class="form-group">
          <label for="password" i18n>Lozinka</label>
          <div class="input-group">
            <input id="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }" [type]="!shown ? 'password' : 'text'" />
            <div class="input-group-append toggable">
              <div class="input-group-text" (click)="viewInput()">
                <fa-icon tooltip="Vidi lozinku" i18n-tooltip="Vidi lozinku" [icon]="faEyeSlash" *ngIf="!shown"></fa-icon>
                <fa-icon tooltip="Sakrij lozinku" i18n-tooltip="Sakrij lozinku" [icon]="faEye" *ngIf="shown"></fa-icon>
              </div>
            </div>
            <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback w-100">
              <div *ngIf="f.password.errors.required" i18n>Lozinka obavezna</div>
              <div *ngIf="f.password.errors.minlength" i18n>Lozinka mora sadržavati najmanje 8 znakova</div>
              <div *ngIf="f.password.errors.maxlength">Lozinka mora sadržavati najviše 64 znaka</div>
              <div *ngIf="f.password.errors.pattern">Lozinka mora sadržavati najmanje jedno veliko slovo, jedno malo slovo, jedan broj i jedan posebni znak</div>
            </div>
          </div>
        </div>

        <password-strength-meter [password]="f.password.value"></password-strength-meter>
        <p class="mb-3" i18n>Jačina lozinke</p>

        <button class="btn rounded-light btn-outline-info mb-3" type="button" (click)="generatePassword()" i18n>Generiraj novu lozinku</button>

      <div class="font-italic mb-3">
        <p i18n>Lozinka mora sadržavati 8-64 znaka</p>
        <p><strong i18n>Savjeti za sigurnu lozinku:</strong></p>
        <p i18n>Koristi velika i mala slova</p>
        <p i18n>Koristi brojeve</p>
        <p i18n>Uključi barem jedan poseban znak (# $ ! % & itd...)</p>
        <p i18n>Izbjegavaj riječi iz rječnika</p>
      </div>


        <input type="hidden" formControlName="recaptcha" class="form-control" />

        <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback w-100">
          <div *ngIf="f.recaptcha.errors.required" i18n>Recaptcha invalid</div>
        </div>

        <div class="d-flex justify-content-center mb-5">
          <app-loader *ngIf="loading"></app-loader>
          <button [disabled]="loading" type="submit" class="btn btn-green" i18n>Spremi novu lozinku</button>
        </div>

        <div class="d-flex justify-content-around">
          <a routerLink="/login" class="btn-link" i18n>Prijavi se</a>
          <!-- <a routerLink="/login" class="btn-link" i18n>Registriraj se</a> -->
        </div>
      </form>
    </div>
  </div>
</div>
