import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {concat, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, first, switchMap, take, tap} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CountryISO} from 'ngx-intl-tel-input';

import {Invoice} from '../../invoice';
import {InvoiceService} from '../../invoice.service';
import {AlertService, AuthenticationService} from '../../../../_services';
import {MutualDependence} from '../../../../_helpers/form-custom-validators';
import {CustomValidator} from '../../../../_components';
import {Contact, ContactService} from '../../../../profile/contacts';
import { InvoiceOwner } from '../../invoice-owner';
import { GeoStruct } from '../../../../_models/geo-struct';
import { GeoStructService } from '../../../../_services/geo-struct.service';
import { Client } from 'src/app/_models';

@Component({
  selector: 'app-invoice-owner-data',
  templateUrl: './invoice-owner-data.component.html'
})
export class InvoiceOwnerDataComponent implements OnInit, OnDestroy {
  @Input() invoice: Invoice;
  @Input() modalRef: BsModalRef;
  @Output() changed = new EventEmitter<boolean>();
  contacts: Contact[] = [];
  ownerDataForm: FormGroup;
  submitted = false;
  loading = {
    form: false,
    contact: false
  };
  private subscription: Subscription = new Subscription();
  disabled = true;
  preferredCountries: CountryISO[] = [CountryISO.Croatia, CountryISO.Germany];
  // TooltipLabel = TooltipLabel;
  countries = [];
  currentCountry = null;
  currentCountryPhone = null;
  private user?: Client;

  public minSearchTermLength = 2;
  public posts: GeoStruct[] = [];
  public states: GeoStruct[] = [];
  public cities$: Observable<GeoStruct[]>;
  public citiesInput$ = new Subject<string>();
  public loadingCities = false;

  constructor(
    private service: InvoiceService,
    private mS: BsModalService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private contactService: ContactService,
    private auth: AuthenticationService,
    private geoStructService: GeoStructService,
  ) {
    this.subscription.add(
      this.auth.currentUser.subscribe((user) => {
        this.user = user;

        if (user) {
          // Create empty object for new contact
          const newContact = new Contact();
          newContact.id = null;
          newContact.email = 'new';
          newContact.country = user.country;
          this.contacts.push(newContact);

          // Add user as contact as well (to change back from contact)
          const userContact = new Contact();
          userContact.id = user.id;
          userContact.email = user.email;
          userContact.firstname = user.firstname;
          userContact.lastname = user.lastname;
          userContact.companyname = user.companyname;
          userContact.tax_id = user.tax_id;
          userContact.address1 = user.address1;
          userContact.address2 = user.address2;
          userContact.city = user.city;
          userContact.state = user.state;
          userContact.country = user.country;
          userContact.postcode = user.postcode;
          const phoneNumber = (typeof user.phonenumber !== 'undefined' && user.phonenumber !== '-') ?
            user.phonenumber.split('.') : ['', ''];
          userContact.phonenumber = phoneNumber[1];
          userContact.telephoneNumber = phoneNumber[1] + phoneNumber[2];
          this.contacts.push(userContact);
        }
      })
    );
  }

  ngOnInit(): void {
    this.loading.contact = true;
    const contSub = this.contactService.getList({})
      .pipe(take(1))
      .subscribe((res) => {
        this.loading.contact = false;
        this.contacts = this.contacts.concat(res);
      });
    this.subscription.add(contSub);

    if (!this.invoice) {
      return;
    }
    let currentCountry = 'HR';
    const currentCountryPhone = 'HR';
    currentCountry = this.invoice.cached_data.invoiceowner.countrycode
      ? this.invoice.cached_data.invoiceowner.countrycode?.toUpperCase()
      : currentCountry;

    for (const [key, value] of Object.entries(CountryISO)) {
      this.countries.push({
        code: value.toUpperCase(),
        name: key
      });
    }
    this.currentCountry = this.countries.find(country => country.code === currentCountry);
    this.currentCountryPhone = this.countries.find(country => country.code === currentCountryPhone);

    this.loadCities();

    this.createForm();
  }

  private createForm() {
    this.ownerDataForm = this.formBuilder.group({
      invoiceid: [this.invoice.invoiceid, Validators.required],
      firstname: [this.invoice.cached_data.invoiceowner.firstname, Validators.required],
      lastname: [this.invoice.cached_data.invoiceowner.lastname, Validators.required],
      companyname: [this.invoice.cached_data.invoiceowner.companyname],
      tax_id: [this.invoice.cached_data.invoiceowner.tax_id],
      address1: [this.invoice.cached_data.invoiceowner.address1, Validators.required],
      address2: [this.invoice.cached_data.invoiceowner.address2],
      city: [ { value: this.invoice.cached_data.invoiceowner.city, disabled: this.disabled }, Validators.required],
      state: [ { value: this.invoice.cached_data.invoiceowner.state, disabled: this.disabled }],
      country: [{ value: this.currentCountry.code, disabled: false }, Validators.required],
      postcode: [ { value: this.invoice.cached_data.invoiceowner.postcode, disabled: this.disabled }, [Validators.minLength(2), CustomValidator.numbericValidator, Validators.required]],
      email: [this.invoice.cached_data.invoiceowner.email, [Validators.email, Validators.required]],
      contactid: null,
      phone: null,
      telephoneNumber: [{value: null, disabled: this.disabled}, Validators.required],
      phonenumber: null
    }, {
      validator: MutualDependence('companyname', 'tax_id')
    });

    const phoneNumber = (this.invoice.cached_data.invoiceowner.phonenumber !== null &&
      this.invoice.cached_data.invoiceowner.phonenumber !== '') ?
      this.invoice.cached_data.invoiceowner.phonenumber.split('.') : '';
    const phone = (phoneNumber.length > 1) ? phoneNumber[1] : ((phoneNumber.length > 0) ? phoneNumber[0] : '');
    const telPhone = (phoneNumber.length > 1) ? phoneNumber[0] + phoneNumber[1] : ((phoneNumber.length > 0) ? phoneNumber[0] : '');
    this.ownerDataForm.patchValue({phonenumber: phone});
    this.ownerDataForm.patchValue({telephoneNumber: telPhone});
  }

  setContact(contact) {
    const cont: Contact = contact;
    const phoneNumber = (typeof cont.phonenumber !== 'undefined' && cont.phonenumber !== '-') ?
      cont.phonenumber.split('.') : ['', ''];
    this.disabled = cont.id !== null;
    this.ownerDataForm.patchValue({
      contactid: cont.id,
      firstname: cont.firstname,
      lastname: cont.lastname,
      companyname: cont.companyname,
      tax_id: cont.tax_id,
      email: (cont.id === null) ? null : cont.email,
      address1: cont.address1,
      address2: cont.address2,
      city: cont.city,
      state: cont.state,
      country: cont.country,
      postcode: cont.postcode,
      phonenumber: phoneNumber[1],
      telephoneNumber: phoneNumber[0] + phoneNumber[1]
    });

    this.currentCountry = this.countries.find(country => country.code === cont.country);
    this.currentCountryPhone = this.countries.find(country => country.code === cont.country);

    const telephoneCtrl = this.ownerDataForm.get('telephoneNumber');
    if (!this.disabled) {
      telephoneCtrl.enable();
      this.ownerDataForm.get('city')?.enable();
      this.ownerDataForm.get('state')?.enable();
      this.ownerDataForm.get('postcode')?.enable();
    } else {
      telephoneCtrl.disable();
      this.ownerDataForm.get('city')?.disable();
      this.ownerDataForm.get('state')?.disable();
      this.ownerDataForm.get('postcode')?.disable();
    }
    this.ownerDataForm.updateValueAndValidity();
  }

  customSearchFn(term: string, item: any) {
    const cont: Contact = item;
    term = term.toLocaleLowerCase();
    if (cont.id === null && cont.email === 'new') {
      return false;
    }
    return cont.firstname.toLocaleLowerCase().indexOf(term) > -1 ||
      cont.lastname.toLocaleLowerCase().indexOf(term) > -1 ||
      (`${cont.firstname} ${cont.lastname}`).toLocaleLowerCase().indexOf(term) > -1 ||
      cont.email.toLocaleLowerCase().indexOf(term) > -1;
  }

  get f() {
    return this.ownerDataForm.controls;
  }

  telInvalidPhone() {
    const defaultClass = 'form-control';
    if (!this.submitted) {
      return defaultClass;
    } else {
      return (this.f.telephoneNumber.errors === null) ? defaultClass : `${defaultClass} is-invalid`;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (!this.ownerDataForm.valid) {
      return false;
    }
    this.loading.form = true;
    // Create new contact first
    let newContactId = null;
    if (this.ownerDataForm.value.hasOwnProperty("contactid")) {
      newContactId = this.ownerDataForm.getRawValue().contactid;
    }

    // If the newContactId is set, then we have a contact that is already created
    // so we can simply update the invoice data
    if (newContactId) {
      this.updateInvoiceData();
      return;
    }

    // If the newContactId is not set and the mail hasn't changed, then we
    // know that the contact is not selected nor should be created
    if (this.ownerDataForm.value.email === this.user.email) {
      this.loading.form = false;
      this.modalRef.hide();
      return;
    }

    // If the newContactId is not set, then we need to create a new contact
    // and then update the invoice data
    this.contactService
      .add(this.ownerDataForm.getRawValue())
      .pipe(first())
      .subscribe(
        (contactId) => {
          if (contactId) {
            this.alertService.success(
              $localize`Kontakt je uspješno kreiran`,
              true
            );
            this.updateInvoiceData();
          } else {
            this.alertService.error(
              $localize`Kontakt nije spremljen. Provjeri podatke i pokušaj ponovno`
            );
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  private updateInvoiceData(): void {
    const formValue = this.ownerDataForm.getRawValue();
    const chosenCountry = this.countries.find(country => country.code === formValue.country);
    const invoiceOwnerData: InvoiceOwner = {
      ...formValue,
      countryname: chosenCountry.name,
      countrycode: chosenCountry.code,
    }

    const updateDataSub = this.service
      .updateInvoiceData(this.invoice.invoiceid, invoiceOwnerData)
      .pipe(take(1))
      .subscribe((res) => {
        this.loading.form = false;
        if (res) {
          if (res.result === "success") {
            this.modalRef.hide();
            this.submitted = true;
            this.alertService.success(res.message);
            this.invoice.cached_data.invoiceowner = res.newdata;
            this.changed.emit(true);
          } else {
            this.alertService.error(res.message);
          }
        } else {
          this.alertService.error(
            $localize`Ne mogu promijeniti podatke na računu`
          );
        }
      });
    this.subscription.add(updateDataSub);
  }

  public trackById(item: any) {
    return item.id;
  }

  public loadCities(): void {
    this.cities$ = concat(
      of([]), // Default to empty array
      this.citiesInput$.pipe(
        filter((res) => {
          return res !== null && res.length >= this.minSearchTermLength;
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => (this.loadingCities = true)),
        switchMap((term) => {
          return this.geoStructService.getPlaces(term).pipe(
            catchError(() => of([])), // Set empty array on error
            tap(() => this.loadingCities = false)
          );
        })
      )
    );
  }

  public onCountryChange(event?: { code: string; name: string }): void {
    // Whenever the country changes, clear out the post code, city and state
    this.ownerDataForm.patchValue({
      postcode: null,
      city: null,
      state: null,
    });
  }

  public onCityChange(city: GeoStruct | null): void {
    // On city change, try to find the state and fetch posts for the city
    if (city) {
      this.geoStructService
        .getStatesForAPlace(city.id)
        .subscribe((counties) => {
          this.states = counties;

          // If we have only one state, we can set it as the default value
          if (counties.length === 1) {
            const state = counties[0];
            this.ownerDataForm.patchValue({ state: state.name });
          }

          // If we have multiple counties, we can show them in the dropdown
          if (counties.length > 1) {
            this.ownerDataForm.patchValue({ state: null });
          }
        });

      this.geoStructService.getPostsForAPlace(city.id).subscribe((posts) => {
        this.posts = posts;

        // If we have only one post, we can set it as the default value
        if (posts.length === 1) {
          const post = posts[0];
          this.ownerDataForm.patchValue({ postcode: post.code });
        }

        // If we have multiple posts, we can show them in the dropdown
        if (posts.length > 1) {
          this.ownerDataForm.patchValue({ postcode: null });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
