import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons';
import {OnExecuteData, ReCaptchaV3Service} from 'ng-recaptcha';

import {AlertService, AuthenticationService, ClientService} from '../_services';
import {SystemService} from '../_services/system.service';
import {APP_CONFIG, AppConfig} from "../app-config.module";


@Component({
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit, OnDestroy {
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  parentForm: FormGroup;
  loading = false;
  submitted = false;
  shown = false;
  // At least one lowercase, one uppercase, one special character, and 8-64 valid characters
  public passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\$%@?+\-`!;,.:#*()_\[\]\{\}])[\w\$%@?+\-`!;,.:#*()_\[\]\{\}]{8,64}$/;

  public recentToken = '';
  public readonly executionLog: OnExecuteData[] = [];

  private allExecutionsSubscription: Subscription;
  private singleExecutionSubscription: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: ClientService,
    private alertService: AlertService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private systemService: SystemService,
    @Inject(APP_CONFIG) public config: AppConfig,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.allExecutionsSubscription = this.recaptchaV3Service.onExecute
      .subscribe((data) => this.executionLog.push(data));
    this.parentForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      companyname: [null],
      address1: [null, Validators.required],
      address2: [null],
      city: [null, Validators.required],
      state: [null, Validators.required],
      postcode: [null, [Validators.required, Validators.minLength(2)]],
      country: [null, Validators.required],
      phonenumber: null,
      telephoneNumber: [null, Validators.required],
      tax_id: [null, [Validators.minLength(5)]],
      language: [null, Validators.required],
      marketingoptin: [null],
      notes: [null],
      clientip: [null],
      twofa: [null],
      tos: [null, Validators.required],
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(64),
          Validators.pattern(this.passPattern),
        ],
      ],
      recaptcha: ["", Validators.required],
      persontype: ["private"],
      customfields1: [""],
      customfields2: ["", Validators.email],
    });

    this.subscriptions.push(
      this.parentForm.get("persontype").valueChanges.subscribe((value) => {
        if (value === "business") {
          this.parentForm
            .get("companyname")
            .setValidators([Validators.required]);
          this.parentForm
            .get("tax_id")
            .setValidators([Validators.required, Validators.minLength(5)]);
        } else {
          this.parentForm.get("companyname").clearValidators();
          this.parentForm.get("tax_id").clearValidators();
        }
        this.parentForm.get("companyname").updateValueAndValidity();
        this.parentForm.get("tax_id").updateValueAndValidity();
      })
    );
  }

  generateRootPassword() {
    const newPass = this.systemService.genRandomPattern(this.passPattern.source);
    this.shown = true;
    this.parentForm.patchValue({password: newPass});
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.parentForm.controls;
  }

  viewInput() {
    this.shown = !this.shown;
  }

  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service.execute(action)
      .subscribe((token) => {
        this.recentToken = token;

        this.parentForm.patchValue({recaptcha: token});
        this.submitted = true;

        const eBillEmail = this.parentForm.controls.customfields2.value ? this.parentForm.controls.customfields2.value : this.parentForm.controls.email.value;
        const eBillOptIn = this.parentForm.controls.customfields1.value;

        this.parentForm.patchValue({
          customfields2: eBillOptIn ? eBillEmail : '', // If eBillOptIn is false, set eBillEmail to empty string
        });

        // stop here if form is invalid
        if (this.parentForm.invalid) {
          return;
        }

        this.loading = true;

        const data = {...this.parentForm.value};
        data.phonenumber = (data.telephoneNumber.internationalNumber !== undefined) ? data.telephoneNumber.internationalNumber : null;

        const customFields = this.systemService.clientCustomFieldsValue;

        // Merge custom fields under 'customfields' key
        // For each through customfields in form and add them to dataToSubmit.customfields
        data.customfields = [];
        for (const key in this.parentForm.value) {
          if (
            this.parentForm.value.hasOwnProperty(key) &&
            key.includes("customfields")
          ) {
            const customField = customFields.customFields.find(
              (field) => field.systemname === key
            );
            if (customField?.id) {
              data.customfields.push({
                id: customField.id,
                value: this.parentForm.value[key],
              });
            }
          }
        }

        const userRegistration = this.userService.register(data)
          .pipe(first())
          .subscribe(tmpUser => {
              this.loading = false;
              if (tmpUser) {
                if (tmpUser.message) {
                  this.alertService.success(tmpUser.message, true);
                } else {
                  this.alertService.success($localize`Registracija je uspješno izvršena`, true);
                }
                this.router.navigate(['/']);
              }
            },
            error => {
              this.alertService.error(error);
              this.loading = false;
            });
        this.allExecutionsSubscription.add(userRegistration);
      });
  }

  onSubmit() {
    this.executeAction('register');
  }

  getTosLink() {
    return `${this.config.tos}`;
  }

  getSupportLink() {
    return `${this.config.appRoot}/support/ticket/new`;
  }

  getContactLink() {
    return `${this.config.contact}`;    
  }

  public ngOnDestroy() {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
