import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, publishLast, refCount, take} from 'rxjs/operators';

import {HandleError, HttpErrorHandler} from '../_services';
import {Domain} from './domain';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {ProductDomainItem} from '../cart/product-domain-item';
import {DomainPricing} from './domain-pricing';
import {DomainWhois} from './domain-whois';
import {Pager} from '../support/tickets/pager';
import {DomainNameserver} from './domain-nameserver';
import {DomainAdditionalField} from './domain-additional-field';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'}),
  observe: 'response' as 'body'
};

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  private readonly handleError: HandleError;
  domainLockStatus;
  domainLockStatusId;
  private nameserverSubject: BehaviorSubject<DomainNameserver[]>;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.handleError = httpErrorHandler.createHandleError('DomainService');
  }

  /**
   * Obtain a list of Client Purchased Domains matching the provided criteria
   * @param filterData Can contain search parameters such as page
   * @param pageSize Determines the size of page size for display
   * @param loadFromCache boolean
   */
  getDomainList(filterData: {}, pageSize: number | 10, loadFromCache = false): Observable<{ pager: Pager, domains: Domain[] }> {
    const pager = new Pager();
    const keyPage = 'page';
    const keyOrderBy = 'orderby';
    const keyOrder = 'order';
    const keyDomainId = 'domainid';
    const keyStatus = 'status';
    const keyName = 'name';
    const allowedOrdering = [
      'domainname',
      'status'
    ];

    pager.currentPage = filterData.hasOwnProperty(keyPage) ? parseInt(filterData[keyPage], 10) : 1;
    pager.pageSize = pageSize;
    const domainid = filterData.hasOwnProperty(keyDomainId) ? filterData[keyDomainId] : null;
    const startIndex = (pager.currentPage === 1) ? 0 : (pageSize * (pager.currentPage - 1));
    const orderby = filterData.hasOwnProperty(keyOrderBy) && allowedOrdering.includes(filterData[keyOrderBy]) ?
      filterData[keyOrderBy] : null;
    const order = filterData.hasOwnProperty(keyOrder) ? filterData[keyOrder] : 'asc';
    const domainStatus = filterData.hasOwnProperty(keyStatus) && filterData[keyStatus] !== 'all' ? filterData[keyStatus] : null;
    const domainName = filterData.hasOwnProperty(keyName) && filterData[keyName] !== '' ? filterData[keyName] : null;
    const data = {
      action: 'GetClientsDomains',
      // limitstart: startIndex,
      // limitnum: pageSize,
      cache: true,
      loadFromCache,
      saveCache: !loadFromCache
    };
    if (domainid) {
      data[keyDomainId] = domainid;
    }
    return this.http.post<HttpResponse<Domain[] | any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map(res => {
          if (res.body.domains !== undefined) {
            const domains = res.body.domains;

            // Sort
            if (orderby) {
              if (order !== 'asc') {
                domains.sort((a, b) => a[orderby] > b[orderby] ? -1 : a[orderby] < b[orderby] ? 1 : 0);
              } else {
                domains.sort((a, b) => a[orderby] < b[orderby] ? -1 : a[orderby] > b[orderby] ? 1 : 0);
              }
            }
            const returnDomainsList = [];
            let index = 0;
            let total = 0;
            for (const domain of domains) {
              // Check status
              if ( (!domainName || (domainName && domain.domainname.includes(domainName))) &&
                ((domainStatus && domain.status.toLowerCase() === domainStatus)
                  || domainStatus === 'all' || domainStatus === null)
              ) {
                total++;
                if (returnDomainsList.length < pageSize && index >= startIndex) {
                  const domainExtensions = domain.domainname.split('.');
                  domain.extension = domain.domainname.replace(domainExtensions[0] + '.', '');
                  returnDomainsList.push(domain);
                }
              }
              index++;
            }
            pager.totalPages = Math.ceil(total / pageSize);
            pager.pages = [...Array(Math.ceil(total / pageSize)).keys()].map(i => (i + 1));
            return {
              pager,
              domains: returnDomainsList
            };
          }
          return {
            pager: new Pager(),
            domains: []
          };
        }),
        catchError(this.handleError('getDomainList', null))
      );
  }

  private mapDomainWhoIs(data) {
    const dataWhois = new DomainWhois();
    dataWhois.address = data.Address_1;
    dataWhois.city = data.City;
    dataWhois.company = data.Company_Name;
    dataWhois.country = data.Country;
    dataWhois.email = data.Email;
    dataWhois.name = data.Full_Name;
    dataWhois.phone = data.Phone_Number;
    dataWhois.zipCode = data.Postcode;
    dataWhois.state = data.State;
    return dataWhois;
  }

  /**
   * Returns specific domain whois by domain id.
   * @param domainId number, domain id
   */
  getDomainWhoisInfo(domainId: number): Observable<{ Registrant: DomainWhois, Admin?: DomainWhois, Tech?: DomainWhois, Billing?: DomainWhois } | any> {
    const data = {
      action: 'DomainGetWhoisInfo',
      domainid: domainId,
      cache: true
    };

    return this.http.post<HttpResponse<any>>
    (`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          let registrantWhois = new DomainWhois();
          
          if (res.body.Registrant) {
            registrantWhois = this.mapDomainWhoIs(res.body.Registrant);
          }

          const whois: any = {
            Registrant: registrantWhois
          };

          // Only add Admin if it exists in response
          if (res.body.Admin) {
            whois.Admin = this.mapDomainWhoIs(res.body.Admin);
          }

          // Only add Tech if it exists in response
          if (res.body.Tech) {
            whois.Tech = this.mapDomainWhoIs(res.body.Tech);
          }

          // Only add Billing if it exists in response
          if (res.body.Billing) {
            whois.Billing = this.mapDomainWhoIs(res.body.Billing);
          }

          return whois;
        }),
        catchError(this.handleError('getDomainWhoisInfo', null))
      );
  }

  /**
   * Updates domains whois info.
   * @param domain Domain object
   * @param whois DomainWhois object
   */
  updateDomainWhoisInfo(domain: Domain, whois:
    { Registrant: DomainWhois, Admin: DomainWhois, Tech: DomainWhois, Billing: DomainWhois }): Observable<boolean> {
    let newWhoisInfoXmlString = '<contactdetails>';
    Object.keys(whois).forEach(contactType => {
      const contactData = whois[contactType];
      const tmpPhone = (typeof contactData.telephoneNumber !== 'undefined' && contactData.telephoneNumber !== null) ?
        contactData.telephoneNumber : null;
      newWhoisInfoXmlString += `<${contactType}>`;
      newWhoisInfoXmlString += `<Full_Name>${(contactData.name !== undefined) ? contactData.name : ''}</Full_Name>`;
      newWhoisInfoXmlString += `<Email>${(contactData.email !== undefined) ? contactData.email : ''}</Email>`;
      newWhoisInfoXmlString += `<Company_Name>${(contactData.company !== undefined) ? contactData.company : ''}</Company_Name>`;
      newWhoisInfoXmlString += `<Address_1>${(contactData.address !== undefined) ? contactData.address : ''}</Address_1>`;
      newWhoisInfoXmlString += `<City>${(contactData.city !== undefined) ? contactData.city : ''}</City>`;
      newWhoisInfoXmlString += `<State>${(contactData.state !== undefined) ? contactData.state : ''}</State>`;
      newWhoisInfoXmlString += `<Country>${(contactData.country !== undefined) ? contactData.country : ''}</Country>`;
      newWhoisInfoXmlString += `<Phone_Country_Code>${(tmpPhone) ? tmpPhone.dialCode.replace('+', '') : ''}</Phone_Country_Code>`;
      newWhoisInfoXmlString += `<Postcode>${(contactData.zipCode !== undefined) ? contactData.zipCode : ''}</Postcode>`;
      newWhoisInfoXmlString += `<Phone_Number>${(tmpPhone) ? tmpPhone.dialCode + '.' + tmpPhone.number.replace(tmpPhone.dialCode, '') : ''}</Phone_Number>`;
      newWhoisInfoXmlString += `</${contactType}>`;
    });
    newWhoisInfoXmlString += '</contactdetails>';
    const data = {
      action: 'DomainUpdateWhoisInfo',
      domainid: domain.id,
      xml: newWhoisInfoXmlString,
      cache: false,
      invalidateKeys: ['DomainGetWhoisInfo', 'GetClientsDomains']
    };

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.result !== undefined) {
            return res.body.result === 'success';
          }
        }),
        catchError(this.handleError('updateDomainWhoisInfo', false))
      );
  }

  /**
   * Gets domains locking status by domain id.
   * @param domainId Domain id
   * @param loadFromCache status to load from cache or not
   */
  getDomainLockingStatus(domainId: number, loadFromCache = false): Observable<string> {
    const data = {
      action: 'DomainGetLockingStatus',
      domainid: domainId,
      cache: true,
      loadFromCache,
      saveCache: !loadFromCache
    };

    if (typeof this.domainLockStatus === 'undefined' || this.domainLockStatusId !== domainId) {
      this.domainLockStatus = this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
        .pipe(
          map((res) => {
            if (res.body.lockstatus !== undefined) {
              return res.body.lockstatus;
            }
            return 'unknown';
          }),
          take(1),
          publishLast(),
          refCount(),
          catchError(this.handleError('getDomainLockingStatus', 'unknown'))
        );
      this.domainLockStatusId = domainId;
    }

    return this.domainLockStatus;
  }

  updateClientDomain(domain: Domain): Observable<boolean> {
    const data = {
      action: 'UpdateClientDomain',
      domainid: domain.id,
      donotrenew: domain.donotrenew,
      paymentmethod: domain.paymentmethod,
      cache: false,
      invalidateKeys: ['DomainGetLockingStatus', 'GetClientsDomains']
    };

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.result !== undefined) {
            // TODO: Check with Mario, domain is returning success but nothing changes
            return res.body.result === 'success';
          }
        }),
        catchError(this.handleError('setDomainLockingStatus', false))
      );
  }

  /**
   * Sends the Update Lock command to the registrar for the domain.
   * Connects to the registrar and attempts to update the lock
   * @param domainId Domain id for which you change status
   * @param lockstatus Boolean true for locked false for unlocked.
   */
  setDomainLockingStatus(domainId: number, lockstatus: boolean): Observable<boolean> {
    const data = {
      action: 'DomainUpdateLockingStatus',
      domainid: domainId,
      lockstatus,
      cache: false,
      invalidateKeys: ['DomainGetLockingStatus', 'GetClientsDomains']
    };

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.result !== undefined) {
            // TODO: Check with Mario, domain is returning success but nothing changes
            return res.body.result === 'success';
          }
        }),
        catchError(this.handleError('setDomainLockingStatus', false))
      );
  }

  /**
   * Returns a list of nameservers for domain
   * @param domainId Domain id
   */
  loadDomainNameservers(domainId: number): Observable<DomainNameserver[]> {
    const data = {
      action: 'DomainGetNameservers',
      domainid: domainId,
      cache: true
    };
    const nameservers = new Array<{ key: string, value: string }>();
    this.nameserverSubject = new BehaviorSubject<DomainNameserver[]>(nameservers);

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.ns1 !== undefined) {
            const numbers = [...Array(10).keys()].map(i => (i + 1));
            for (const num of numbers) {
              const nsKey = `ns${num}`;
              if (res.body[nsKey] !== undefined && res.body[nsKey] !== null) {
                nameservers.push({key: nsKey, value: res.body[nsKey]});
              } else {
                break;
              }
            }
          }
          this.nameserverSubject.next(nameservers);
        }),
        catchError(this.handleError('getDomainNameservers', null))
      );
  }

  getDomainNameservers(domainId: number): Observable<DomainNameserver[]> {
    return this.nameserverSubject.asObservable();
  }

  /**
   *  Updates nameservers for domain
   */
  updateDomainNameservers(domainId: number, nameservers: DomainNameserver[] | any): Observable<boolean> {
    const data = {
      action: 'DomainUpdateNameservers',
      domainid: domainId,
      cache: false,
      invalidateKeys: ['DomainGetNameservers', 'GetClientsDomains']
    };
    for (const nameserver of nameservers) {
      data[nameserver.key] = nameserver.value;
    }

    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          if (res.body.result !== undefined) {
            this.nameserverSubject.next(nameservers);
            return res.body.result === 'success';
          }
        }),
        catchError(this.handleError('updateDomainNameservers', false))
      );
  }

  /**
   * Retrieves default nameservers
   */
  getDefaultNameservers(): Observable<DomainNameserver[]> {
    const data = {
      action: 'GetDefaultNameservers',
      cache: true
    };
    return this.http.post<HttpResponse<any | DomainNameserver[]>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          const nameservers: DomainNameserver[] = [];
          const dt = (typeof res.body.result !== 'undefined' && res.body.result === 'success' && typeof res.body.message !== 'undefined') ?
            res.body.message : null;
          if (dt) {
            dt.forEach((ns, index) => {
              const nameserver = new DomainNameserver();
              nameserver.key = `ns${index + 1}`;
              nameserver.value = ns;
              nameservers.push(nameserver);
            });
          }
          return nameservers;
        }),
        catchError(this.handleError('getdefaultNameservers', []))
      );
  }

  /**
   * Search for domains by value string.
   * Mock data is returned for now
   * @param value Search string for domain
   */
  searchDomains(value: string,): Observable<ProductDomainItem[]> {
    const list: ProductDomainItem[] = new Array<ProductDomainItem>();
    this.getTldPricing()
      .pipe(take(1))
      .subscribe((pricings) => {
        for (const pricing of pricings) {
          const domain = new ProductDomainItem();
          domain.id = value + '.' + pricing.name;
          domain.name = value + '.' + pricing.name;

          this.getDomainWhois(domain.name)
            .pipe(take(1))
            .subscribe((whois) => {
              domain.status = whois.status;
              domain.whois = whois.whois;
            });
          domain.idprotect = pricing.addons.idprotect;
          // TODO: not sure how these prices work
          domain.prices = new Array<{ id: number, value: number }>();
          for (const key in pricing.register) {
            if (pricing.register.hasOwnProperty(key)) {
              domain.prices.push({id: +key, value: +pricing.register[key]});
            }
          }

          domain.months = 12; // TODO: Connected to prices, check to see what those pricing means first
          domain.added = false;
          list.push(domain);
        }
      });
    return of(list);
  }

  /**
   * Gets pricing for client and/or currency
   * @param currencyId The currency ID to fetch pricing for
   * @param clientId The id of the client to fetch pricing for. Pass one or the other. clientId being passed will override currencyId
   */
  getTldPricing(currencyId: number = null, clientId: number = null): Observable<DomainPricing[]> {
    const data = {
      action: 'GetTLDPricing',
      currencyid: currencyId,
      cache: true
    };
    return this.http.post<HttpResponse<DomainPricing[] | any>>(`${this.config.apiEndpoint}/public/request`, data, httpOptions)
      .pipe(
        map((res) => {
          const pricings = res.body.pricing;
          const returnPricingsList: DomainPricing[] = [];
          for (const key in pricings) {
            if (pricings.hasOwnProperty(key)) {
              const domainPricing = new DomainPricing();
              domainPricing.name = key;
              domainPricing.additionalFields = pricings[key].additionalfields;
              const addFields = [];
              if (pricings[key].additionalfields !== undefined) {
                pricings[key].additionalfields.forEach(x => {
                  const addField = new DomainAdditionalField();
                  addField.default = (x.Default !== undefined) ? x.Default : undefined;
                  addField.description = (x.Description !== undefined) ? x.Description : undefined;
                  addField.langVar = (x.LangVar !== undefined) ? x.LangVar : undefined;
                  addField.name = (x.Name !== undefined) ? x.Name : undefined;
                  addField.options = (x.Options !== undefined) ? x.Options.split(',') : undefined;
                  addField.size = (x.Size !== undefined) ? x.Size : undefined;
                  addField.type = (x.Type !== undefined) ? x.Type : undefined;
                  addField.required = (x.Required !== undefined && x.Required === true);
                  addField.value = null;
                  addFields.push(addField);
                });
              }
              domainPricing.additionalFields = addFields;
              domainPricing.addons = pricings[key].addons;
              domainPricing.categories = pricings[key].categories;
              domainPricing.gracePeriod = pricings[key].grace_period;
              domainPricing.group = pricings[key].group;
              domainPricing.redemptionPeriod = pricings[key].redemption_period;
              domainPricing.register = pricings[key].register;
              domainPricing.renew = pricings[key].renew;
              domainPricing.transfer = pricings[key].transfer;
              domainPricing.eppCode = (pricings[key].eppcode === 1);
              returnPricingsList.push(domainPricing);
            }
          }
          return returnPricingsList;
        }),
        catchError(this.handleError('getTldPricing', []))
      );
  }

  validateDomainAdditionalFields(addFields: DomainAdditionalField[]) {
    let allValid = true;
    addFields.forEach(x => {
      if (x.required && x.value === '') {
        allValid = false;
        if (x.errors === undefined) {
          x.errors = {
            required: true
          };
        }
      } else {
        delete x.errors;
      }
    });

    return allValid;
  }

  /**
   * Get domains whois information.
   * Domain must consist of name and extension.
   * @param domainString Domain name with extension (e.g. some-domain.com)
   */
  getDomainWhois(domainString: string): Observable<{ status: string, whois: string }> {
    const data = {
      action: 'DomainWhois',
      domain: domainString,
      cache: true
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/public/request`, data, httpOptions)
      .pipe(
        map(res => {
          return {
            status: (typeof res.body.status !== 'undefined') ? res.body.status : null,
            whois: (typeof res.body.whois !== 'undefined') ? res.body.whois : null,
          };
        }),
        catchError(this.handleError('domainWhois', null))
      );
  }

  /**
   * Sends the Request EPP command to the registrar for the domain.
   * Connects to the registrar and attempts to obtain the EPP Code for the domain.
   * Not all registrars return the EPP code but send them directly to the client.
   * @param domain Domain
   */
  public getDomainEpp(domain: Domain): Observable<{ result: boolean, eppcode: string }> {
    const data = {
      action: 'DomainRequestEPP',
      domainid: domain.id,
      cache: true
    };
    return this.http.post<HttpResponse<any>>(`${this.config.apiEndpoint}/user/request`, data, httpOptions)
      .pipe(
        map((res) => {
          let result = false;
          let eppcode = '';
          if (res.body.result !== undefined) {
            result = res.body.result === 'success';
          }
          if (res.body.eppcode !== undefined) {
            eppcode = res.body.eppcode;
          }
          return {
            result,
            eppcode
          };
        }),
        catchError(this.handleError('getDomainEpp', null))
      );
  }
}
