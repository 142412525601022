<div class="bd-bottom p-3 bg-white">
  <h3 class="font-weight-bold text-color-2" i18n>Suradnički račun</h3>
</div>

<div class="container-fluid">
  <ng-container *ngIf="affiliateActive">
    <div class="row p-3" *ngIf="affiliate && !loading">
      <div class="col-12 col-sm-4 mb-3 mb-sm-0 p-2">
        <div class="shadow p-2 bg-white rounded-light text-center">
          <p i18n>Klikova</p>
          <p class="font-weight-bold flarge">{{affiliate.visitors}}</p>
        </div>
      </div>
      <div class="col-12 col-sm-4 mb-3 mb-sm-0 p-2">
        <div class="shadow p-2 bg-white rounded-light text-center">
          <p i18n>Broj prijava</p>
          <p class="font-weight-bold flarge">{{affiliate.signups}}</p>
        </div>
      </div>
      <div class="col-12 col-sm-4 mb-3 mb-sm-0 p-2">
        <div class="shadow p-2 bg-white rounded-light text-center">
          <p i18n>Stopa pretvorbe:</p>
          <p class="font-weight-bold flarge">{{affiliate.conversionrate}}</p>
        </div>
      </div>
    </div>

    <h5 class="mb-3" i18n>Tvoj jedinstveni suradnički link</h5>
    <div class="row form-inline">
      <div class="col-12 col-sm-8 mb-3 mb-sm-0 form-group">
        <input type="text" readonly class="form-control col-sm-12 bg-white" value="{{affiliateLink}}">
      </div>
      <div class="col-12 col-sm-3 form-group">
        <button class="btn rounded-light btn-color2 w-100" (click)="copyLink(affiliateLink)" i18n>Kopiraj link</button>
      </div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="!affiliateActive && !loading">
  <div class="p-3">
    <h5 class="mb-3" i18n>Sudjeluj u suradničkom programu i dodatno zaradi</h5>
    <p><button class="btn rounded-light btn-color2" (click)="activateAffiliate()" i18n>Aktiviraj</button></p>
  </div>
</ng-container>

<div class="p-3">
  <app-loader *ngIf="loading"></app-loader>
</div>
<hr>

<ng-container *ngIf="affiliateActive">
  <h5 class="mb-3 p-3" i18n>Iznos za isplatu</h5>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 col-xl-3 mb-2 mb-lg-0 font-weight-bold"><span i18n>Iznos na čekanju:</span></div>
      <div class="col-6 col-xl-9 mb-2 mb-lg-0">{{affiliate.pendingcommissions | currency : currentCurrency.code : 'symbol-narrow'}}&nbsp;<span *ngIf="currentCurrency.code === 'EUR' && displayHrk">({{affiliate.pendingcommissions*7.53450 | number: '1.2-2'}} HRK)</span></div>
      <div class="col-6 col-xl-3 mb-2 mb-xl-0 font-weight-bold"><span i18n>Dostupan suradnički iznos:</span></div>
      <div class="col-6 col-xl-9 mb-2 mb-xl-0">{{affiliate.balancenum | currency : currentCurrency.code : 'symbol-narrow'}}&nbsp;<span *ngIf="currentCurrency.code === 'EUR' && displayHrk">({{affiliate.balancenum*7.53450 | number: '1.2-2'}} HRK)</span></div>
      <div class="col-6 col-xl-3 mb-2 mb-xl-0 font-weight-bold"><span i18n>Ukupan iznos isplate:</span></div>
      <div class="col-6 col-xl-9 mb-2">{{affiliate.withdrawnnum | currency : currentCurrency.code : 'symbol-narrow'}}&nbsp;<span *ngIf="currentCurrency.code === 'EUR' && displayHrk">({{affiliate.withdrawnnum*7.53450 | number: '1.2-2'}} HRK)</span></div>
    </div>
  </div>

  <div class="p-3">
    <button *ngIf="affiliate.balancenum > affiliate.affiliatePayoutMinimumNum" (click)="withdrawBalance()" class="btn rounded-light btn-green mb-3" i18n>Zatraži isplatu</button>
    <p class="font-italic">
      <span i18n>*Min. iznos za isplatu je</span> {{affiliate.affiliatePayoutMinimumNum | currency : currentCurrency.code : 'symbol-narrow'}}
      &nbsp;<span *ngIf="currentCurrency.code === 'EUR' && displayHrk">({{affiliate.affiliatePayoutMinimumNum*7.53450 | number: '1.2-2'}} HRK)</span>
    </p>

    <app-ticket-new [withdraw]="true" *ngIf="withdraw"></app-ticket-new>
  </div>
</ng-container>


<hr *ngIf="affiliateActive">


  <accordion [isAnimated]="true" [closeOthers]="true" *ngIf="!loading && affiliate && affiliateActive">
    <accordion-group [isOpen]="false" panelClass="p-0 bg-grey border-0">
      <div class="btn btn-link accordion-heading p-0 clearfix" accordion-heading>
        <h5 i18n>Korisnici upućeni putem tvog suradničkog računa</h5>
      </div>
      <div class="container-fluid">
        <div class="row bg-color2 p-2 d-none d-lg-flex">
          <div class="col-1" i18n>ID</div>
          <div class="col-2" i18n>Datum narudžbe</div>
          <div class="col-2" i18n>Datum aktivacije</div>
          <div class="col-3" i18n>Proizvod/usluga</div>
          <div class="col-1" i18n>Cijena</div>
          <div class="col-1" i18n>Provizija</div>
          <div class="col-2" i18n>Status</div>
        </div>

        <ng-container *ngIf="affiliate?.referrals.length">
          <div class="row bd-bottom p-lg-2" *ngFor="let referral of affiliate.referrals">
            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>ID narudžbe</div>
            <div class="col-8 mb-2 col-lg-1 mb-lg-0">{{referral.id}}</div>
            
            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Datum narudžbe</div>
            <div class="col-8 mb-2 col-lg-2 mb-lg-0">{{referral.orderdate | date: 'dd.MM.yyyy.'}}</div>
            
            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Datum aktivacije</div>
            <div class="col-8 mb-2 col-lg-2 mb-lg-0">{{referral.date | date: 'dd.MM.yyyy.'}}</div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Proizvod/usluga</div>
            <div class="col-8 mb-2 col-lg-3 mb-lg-0">{{getServiceName(referral.service)}}</div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Cijena</div>
            <div class="col-8 mb-2 col-lg-1 mb-lg-0"> {{referral.amount}} {{referral.billingcycle}}  </div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Provizija</div>
            <div class="col-8 mb-2 col-lg-1 mb-lg-0">{{referral.commission}}</div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Status</div>
            <div class="col-8 mb-2 col-lg-2 mb-lg-0">{{referral.status}}</div>
          </div>
        </ng-container>
      </div>
    </accordion-group>

    <accordion-group [isOpen]="false" panelClass="p-0 bg-grey border-0">
      <div class="btn btn-link accordion-heading p-0 clearfix" accordion-heading>
        <h5 i18n>Popis priljeva po suradničkom računu</h5>
      </div>
      <div class="container-fluid">
        <div class="row bg-color2 p-2 d-none d-lg-flex">
          <div class="col-4" i18n>Datum</div>
          <div class="col-4" i18n>ID narudžbe</div>
          <div class="col-4" i18n>Iznos</div>
        </div>

        <ng-container *ngIf="paginatedCommissionHistory.length">
          <div class="row bd-bottom p-lg-2" *ngFor="let comission of paginatedCommissionHistory">
            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Datum narudžbe</div>
            <div class="col-8 mb-2 col-lg-4 mb-lg-0">{{comission.date | date: 'dd.MM.yyyy.'}}</div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>ID narudžbe</div>
            <div class="col-8 mb-2 col-lg-4 mb-lg-0">{{comission.referralid}}</div>

            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Iznos</div>
            <div class="col-8 mb-2 col-lg-4 mb-lg-0">{{comission.amount}}</div>
          </div>
        </ng-container>

        <app-non-route-pager (onPageChange)="onCommissionHistoryPageChange($event)" [pager]="commissionHistoryPager"></app-non-route-pager>

        <div *ngIf="paginatedCommissionHistory?.length === 0" i18n>
          Nemaš povijesti priljeva po suradničkom računu
        </div>
      </div>
    </accordion-group>

    <accordion-group [isOpen]="false" panelClass="p-0 bg-grey border-0">
      <div class="btn btn-link accordion-heading p-0 clearfix" accordion-heading>
        <h5 i18n>Popis isplata</h5>
      </div>
      <div class="container-fluid">
        <div class="row bg-color2 p-2 d-none d-lg-flex">
          <div class="col-4" i18n>Datum isplate</div>
          <div class="col-4" i18n>Iznos</div>
        </div>
  
        <ng-container *ngIf="affiliate?.withdrawalshistory.length">
          <div
            class="row bd-bottom p-lg-2"
            *ngFor="let withdrawal of affiliate.withdrawalshistory"
          >
            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Datum isplate</div>
            <div class="col-8 mb-2 col-lg-4 mb-lg-0">
              {{ withdrawal.datets * 1000 | date : "shortDate" }}
            </div>
  
            <div class="col-4 mb-2 font-weight-bold d-lg-none" i18n>Iznos</div>
            <div class="col-8 mb-2 col-lg-4 mb-lg-0">
              {{ withdrawal.amount }}
            </div>
          </div>
        </ng-container>
      </div>
    </accordion-group>  

    <accordion-group [isOpen]="false" panelClass="p-0 bg-grey border-0" *ngIf="affiliate.affiliatelinkscode">
      <div class="btn btn-link accordion-heading p-0 clearfix" accordion-heading>
        <h5 i18n>Suradnički link u obliku bannera</h5>
      </div>
      <div class="container-fluid">
        <ng-container *ngFor="let link of affiliate.affiliatelinkscode; let last = last;">
          <div class="row mb-2">
            <div class="col-12 p-2 max-w-1" [innerHTML]="link"></div>
          </div>
          <div class="row mb-5">
            <code class="col-12 col-lg-9 d-block mb-2 mb-lg-0">{{link}}</code>
            <button class="col-12 col-lg-2 offset-lg-1 btn rounded-light btn-color2" (click)="copyLink(link)" i18n>Kopiraj link</button>
          </div>
          <hr *ngIf="!last">
        </ng-container>
      </div>
    </accordion-group>
  </accordion>

<div class="p-3">
  <a appExternalUrl [href]="getFaqLink()"><h5 i18n>Što je suradnički račun i kako funkcionira?</h5></a>
</div>
<div class="euro-banner p-2 text-center" *ngIf="currentCurrency.code === 'EUR' && displayHrk">Prema čl. 42 Zakona o uvođenju eura kao službene valute u Republici Hrvatskoj, dvojno iskazivanja cijena prema potrošačima u RH prikazuje se po fiksnom tečaju 1€ = 7,53450kn</div>
