import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {Client} from '../_models';
import {ClientService} from './client.service';
import {Currency} from '../_models/currency';
import {AlertService} from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static currentUserKey = 'currentUser';
  public static currencyKey = 'currency';
  public static twoFaLinkKey = 'twoFaLink';

  public currentUser: Observable<Client | null>;
  public currency: Observable<Currency>;
  private currentUserSubject: BehaviorSubject<Client | null>;
  private currencySubject: BehaviorSubject<Currency>;

  constructor(
    private userService: ClientService,
    private alertService: AlertService
  ) {
    this.currentUserSubject = new BehaviorSubject<Client>(JSON.parse(this.getStorage( AuthenticationService.currentUserKey, 'session')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.currencySubject = new BehaviorSubject<Currency>(JSON.parse(this.getStorage(AuthenticationService.currencyKey, 'session')));
    this.currency = this.currencySubject.asObservable();
  }

  public get currentUserValue(): Client {
    return this.currentUserSubject.value;
  }

  public get currentCurrencyValue(): Currency {
    return this.currencySubject.value;
  }

  setCurrency(currency: Currency) {
    this.addStorage(AuthenticationService.currencyKey, JSON.stringify(currency), 'session');
    this.currencySubject.next(currency);
  }

  updateUser(user: Client) {
    this.addStorage(AuthenticationService.currentUserKey, JSON.stringify(user), 'session');
    this.currentUserSubject.next(user);
  }

  /**
   * Refresh current user data
   */
  getUserDetails() {
    return this.userService.getCurrentUserInfo().pipe(
      map(res => {
        let user = null;
        user = (res === null) ? null : res.body;
        return user;
      })
    );
  }

  /**
   * Update language and get user data
   * @param shortCode string
   */
  updateUserLanguage(shortCode: string) {
    return this.userService.updateUserLanguage(shortCode).pipe(
      map(res => {
        let user = null;
        user = (res === null) ? null : res.body;
        return user;
      })
    );
  }

  isAllowed(action: string) {
    return (this.currentUserValue && this.currentUserValue.permissions !== null && typeof this.currentUserValue.permissions !== 'undefined'
        && this.currentUserValue.permissions.indexOf(action) >= 0);
  }

  login(email: string, password: string, remember: boolean) {
    return this.userService.authenticateUser(email, password)
      .pipe(
        map(res => {
          const status = (res === null) ? null : res.status;
          let user = null;
          if (status === 202) {
            user = new Client();
            user.qrlink = res.body.error_description.qrlink;
            user.nonce = res.body.error_description.nonce;
          } else {
            user = (res === null) ? null : res.body;
          }

          if (user && user.qrlink === undefined) {
            user.customfields = (typeof user.customfields !== 'undefined') ? btoa(user.customfields) : null;
            user.persontype = ( user.customfields === null || typeof user.customfields.persontype === 'undefined')
              ? 'private' : user.customfields.persontype;
            user.persontype =  (user.tax_id && user.tax_id !== '' && user.companyname && user.companyname !== '') ? 'business' : user.persontype;
            user.currencyCode = user.currency_code;
            this.addStorage(AuthenticationService.currentUserKey, JSON.stringify(user), 'session');
            this.currentUserSubject.next(user);
          }

          // Save 2FA link in case user encounters error and needs to re-scan QR code
          if(user?.qrlink && user.qrlink !== 'token_already_set') {
            // Link is in format: otpauth://totp/{name:email}?secret={secret}&issuer={issuer}
            const qrLink = res.body.error_description.qrlink;
            const urlParams = new URLSearchParams(new URL(qrLink).search);
            const qrCodeData = urlParams.get('chl');
            user.qrlink = decodeURIComponent(qrCodeData);
            this.addStorage(AuthenticationService.twoFaLinkKey, user.qrlink, 'session');
          }

          return user;
        }));
  }

  loginGoogle(nonce: string, code: string) {
    return this.userService.authenticateGoogleUser(nonce, code)
      .pipe(map(res => {
        const status = (res === null) ? null : res.status;
        let user = null;
        if (status === 202) {
          user = new Client();
          user.nonce = res.body.error_description.nonce;
          user.message = res.body.error_description.message;
        } else {
          user = (res === null) ? null : res.body;
        }

        if (user !== null && user.nonce === undefined) {
          user.customfields = (typeof user.customfields !== 'undefined') ? btoa(user.customfields) : null;
            user.persontype = ( user.customfields === null || typeof user.customfields.persontype === 'undefined')
              ? 'private' : user.customfields.persontype;
            user.persontype =  (user.tax_id && user.tax_id !== '' && user.companyname && user.companyname !== '') ? 'business' : user.persontype;
            user.currencyCode = user.currency_code;
          this.addStorage(AuthenticationService.currentUserKey, JSON.stringify(user), 'session');
          this.currentUserSubject.next(user);
        }

        if (!user.message) {
          // Remove 2FA link from session storage if user successfully logs in
          this.removeStorage(AuthenticationService.twoFaLinkKey, 'session');
        }

        return user;
      }));
  }

  /**
   * Authorize and get user details only by nonce parameter
   * @param nonce string
   */
   loginUserByNonce(nonce: string) {
    return this.userService.authenticateUserByNonce(nonce)
      .pipe(
        map(res => {
          const status = (res === null) ? null : res.status;
          let user = null;
          if (status === 202) {
            user = new Client();
            user.qrlink = res.body.error_description.qrlink;
            user.nonce = res.body.error_description.nonce;
          } else {
            user = (res === null) ? null : res.body;
          }

          if (user && user.qrlink === undefined) {
            user.customfields = (typeof user.customfields !== 'undefined') ? btoa(user.customfields) : null;
            user.persontype = (user.customfields === null || typeof user.customfields.persontype === 'undefined')
              ? 'private' : user.customfields.persontype;
            user.persontype =  (user.tax_id && user.tax_id !== '' && user.companyname && user.companyname !== '') ? 'business' : user.persontype;
            user.currencyCode = user.currency_code;
            this.addStorage(AuthenticationService.currentUserKey, JSON.stringify(user), 'session');
            this.currentUserSubject.next(user);
          }

          return user;
        }));
  }

  addStorage(key: string, item, type: string = 'local') {
    if (type === 'local') {
      this.addLocalStorage(key, item);
    } else {
      this.addSessionStorage(key, item);
    }
  }

  getStorage(key: string, type: string = 'local'): any {
    if (type === 'local') {
      return this.getLocalStorage(key);
    } else {
      return this.getSessionStorage(key);
    }
  }

  removeStorage(key: string, type: string = 'local') {
    if (type === 'local') {
      this.removeLocalStorage(key);
    } else {
      this.removeSessionStorage(key);
    }
  }

  addLocalStorage(key: string, item) {
    localStorage.setItem(key, item);
  }

  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  getLocalStorage(key: string): any {
    return localStorage.getItem(key);
  }

  addSessionStorage(key: string, item) {
    sessionStorage.setItem(key, item);
  }

  removeSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

  getSessionStorage(key: string): any {
    return sessionStorage.getItem(key);
  }

  onStorageChange(event) {
    const credentials = JSON.parse(this.getStorage(AuthenticationService.currentUserKey, 'session'));
    if (event.key === 'REQUESTING_SHARED_CREDENTIALS_KNOX' && credentials) {
      this.addStorage('CREDENTIALS_SHARING_KNOX', JSON.stringify(credentials));
      this.removeStorage('CREDENTIALS_SHARING_KNOX');
      return false;
    }
    if (event.key === 'CREDENTIALS_SHARING_KNOX' && !credentials) {
      this.removeStorage('CREDENTIALS_SHARING_KNOX_CUR');
      const user: Client = JSON.parse(event.newValue);
      this.addStorage(AuthenticationService.currentUserKey, event.newValue, 'session');
      if (typeof this.currentUserSubject === 'undefined') {
        this.currentUserSubject = new BehaviorSubject<Client>(user);
        this.currentUser = this.currentUserSubject.asObservable();
      } else {
        this.currentUserSubject.next(JSON.parse(event.newValue));
      }
      return true;
    }
    if (event.key === 'CREDENTIALS_FLUSH' && credentials) {
      this.removeStorage(AuthenticationService.currentUserKey, 'session');
      this.removeStorage(AuthenticationService.currencyKey, 'session');
      this.currentUserSubject.next(null);
      this.currencySubject.next(null);
      return true;
    }
  }

  getAuthorizationToken() {
    if (!this.currentUserValue) {
      return '';
    }
    return this.currentUserValue.token;
  }

  logout(skipService) {
    if (!skipService) {
      this.userService.logoutUser()
        .pipe(first())
        .subscribe( status => {
            this.removeStorage(AuthenticationService.currentUserKey, 'session');
            this.removeStorage(AuthenticationService.currencyKey, 'session');
            this.addStorage('CREDENTIALS_FLUSH', Date.now().toString());
            this.removeStorage('CREDENTIALS_FLUSH');
          },
          error => {
            this.alertService.error($localize`Došlo je do greške prilikom odjave`, true);
          });
    } else {
      this.removeStorage(AuthenticationService.currentUserKey, 'session');
      this.addStorage('CREDENTIALS_FLUSH', Date.now().toString());
      this.removeStorage('CREDENTIALS_FLUSH')
    }
    this.currentUserSubject.next(null);
  }
}
