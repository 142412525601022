import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';

import {CartService} from '../../cart.service';
import {OrderService} from '../../order.service';
import {CartOrderItem} from '../../cart-order-item';
import {ProductDomainItem} from '../../product-domain-item';
import {AlertService, AuthenticationService} from '../../../_services';
import {PaymentMethod} from '../../../_models/payment-method';
import {SystemService} from '../../../_services/system.service';
import {Client} from '../../../_models';
import {ProductService} from "../../../_services/product.service";

@Component({
  selector: 'app-step-payment',
  templateUrl: './step-payment.component.html'
})
export class StepPaymentComponent implements OnDestroy, OnInit {
  private subscription: Subscription = new Subscription();
  @Input() product: string;
  @Input() filter: { gId: number };
  orderItems: CartOrderItem[];
  domainItems: ProductDomainItem[];
  orderForm: FormGroup;
  paymentMethods: PaymentMethod[] = [];
  submitted = false;
  client: Client;
  loading = {
    paymentMethods: false,
    form: false
  };

  constructor(
    private router: Router,
    private cartService: CartService,
    private orderService: OrderService,
    private alertService: AlertService,
    private systemService: SystemService,
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private authenticationService: AuthenticationService
  ) {
    this.client = this.authenticationService.currentUserValue;
    this.orderForm = this.formBuilder.group({
      currency: null,
      coupon: null,
      affId: null,
      total: null,
      contactid: null,
      paymentMethod: [null, Validators.required]
    });

    this.loading.paymentMethods = true;
    const payMethSub = this.systemService.getPaymentMethods()
      .pipe(take(1))
      .subscribe((res) => {
          this.loading.paymentMethods = false;
          this.paymentMethods = res;
        }
      );
    this.subscription.add(payMethSub);

    const itemSub = this.cartService.orderItems$
      .subscribe((orderItems) => {
        this.orderItems = orderItems;
        this.checkItems();
      });
    this.subscription.add(itemSub);

    const subDomain = this.cartService.domainItems$
      .subscribe((domainItems) => {
        this.domainItems = domainItems;
        this.checkItems();
      });
    this.subscription.add(subDomain);

    const subOrder = this.orderService.order$
      .subscribe((order) => {
        if (!order) {
          // this.alertService.error('Confirm order', true);
          return this.router.navigate(['/cart/overview']);
        }
        this.orderForm.patchValue({currency: order.currency});
        this.orderForm.patchValue({coupon: order.coupon});
        this.orderForm.patchValue({affId: order.affId});
        this.orderForm.patchValue({total: order.total});
        this.orderForm.patchValue({contactid: order.contactid});
      });
    this.subscription.add(subOrder);
  }

  ngOnInit() {
    this.orderForm.patchValue({affId: this.cartService.getAff()});
    this.orderForm.patchValue({contactid: this.cartService.getDomainContact()?.contactid});
  }

  checkItems() {
    if (!this.orderItems || !this.domainItems) {
      return;
    }
    if (this.orderItems.length === 0 && this.domainItems.length === 0) {
      // this.alertService.error('Empty items', true);
      return this.router.navigate(['/cart']);
    }
  }

  get f() {
    return this.orderForm.controls;
  }

  createOrder() {
    this.submitted = true;
    if (this.orderForm.invalid) {
      return false;
    }
    this.loading.form = true;
    const orderSub = this.orderService
      .createOrder(this.orderForm.value, this.orderItems, this.domainItems)
      .subscribe((order) => {
        this.loading.form = false;
        if (order && order.invoiceid !== undefined) {
          this.loadProducts();


          this.router.navigate([`/accounting/bill/${order.invoiceid}`]);
        }
      });
    this.subscription.add(orderSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadProducts() {
    // this.loading.products = true;
    const productListSubs = this.productService.getClientProducts({
      limitstart: 0,
      limitnum: 200
    })
      .pipe(take(1))
      .subscribe(products => {
        // this.loading.products = false;
        // this.products = products;
      });
    this.subscription.add(productListSubs);
  }
}
